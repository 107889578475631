<template>
  <div>
    <LoaderView v-if="!branding" />
    <ToasterView />
    <div class="mainView" v-if="branding">
      <HeaderView_Default v-if="branding?.display_options?.template == 'default'" />
      <HeaderView_Bento v-if="branding?.display_options?.template == 'bento'" />
      <router-view class="content" v-slot="{ Component }">
        <transition name="page" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import HeaderView_Default from '@/components/Main/Layout/Default/HeaderView.vue'
import HeaderView_Bento from '@/components/Main/Layout/Bento/HeaderView.vue'
import ToasterView from '@/components/Toaster/ToasterView.vue'
import LoaderView from '@/components/Loaders/LoaderView.vue'

export default {
  components: {
    HeaderView_Default,
    HeaderView_Bento,
    ToasterView,
    LoaderView,
  },
  computed: {
    ...mapState(['selectedService', 'toaster', 'branding', 'storeConfig']),
  },
  watch: {
    branding(newVal) {
      this.setFonts(newVal)
      this.setStyling(newVal)
      this.setHeadTags(newVal)
    },
    storeConfig() {
      // set app name
      let appName = this.storeConfig.chain_display_name
      document.querySelector('meta[name="application-name"]').content = appName
    },
  },
  methods: {
    ...mapMutations(['SET_LOGGED_IN', 'SET_ORDER_DATA', 'SET_DAY_AND_TIME_SELECTED']),
    goToLocationsPage() {
      this.$router.push({ name: 'HomeView' })
    },
    setFonts(newVal) {
      const link = document.createElement('link')
      link.href = newVal.fonts.regular_font.font_url
      link.rel = 'stylesheet'
      link.rel = 'preload'
      link.as = 'font'
      link.crossOrigin = 'anonymous'
      document.head.appendChild(link)
      // add font-face to css
      let fontFace = `@font-face {
        font-family: ${newVal.fonts.regular_font.font_name};
        src: url(${newVal.fonts.regular_font.font_url});
      }`
      document.styleSheets[0].insertRule(fontFace, 0)

      // set regular font as a css variable
      let regularFontName = newVal.fonts.regular_font.font_name + ', sans-serif'
      document.documentElement.style.setProperty('--regular-font', regularFontName)

      // set regular font size_multiplier as a css variable
      let regularFontSizeMultiplier = newVal.fonts.regular_font.size_multiplier
        ? newVal.fonts.regular_font.size_multiplier
        : 1
      document.documentElement.style.setProperty('--regular-font-size-multiplier', regularFontSizeMultiplier)

      const link2 = document.createElement('link')
      link.href = newVal.fonts.header_font.font_url
      link.rel = 'stylesheet'
      link.rel = 'preload'
      link.as = 'font'
      link.crossOrigin = 'anonymous'
      document.head.appendChild(link2)
      // add font-face to css
      let fontFace2 = `@font-face {
        font-family: ${newVal.fonts.header_font.font_name};
        src: url(${newVal.fonts.header_font.font_url});
      }`
      document.styleSheets[0].insertRule(fontFace2, 0)

      // set header font as a css variable
      let headerFontName = newVal.fonts.header_font.font_name + ', sans-serif'
      document.documentElement.style.setProperty('--header-font', headerFontName)

      // set header font size_multiplier as a css variable
      let headerFontSizeMultiplier = newVal.fonts.header_font.size_multiplier
        ? newVal.fonts.header_font.size_multiplier
        : 1
      document.documentElement.style.setProperty('--header-font-size-multiplier', headerFontSizeMultiplier)

      const link3 = document.createElement('link')
      link.href = newVal.fonts.regular_bold_font.font_url
      link.rel = 'stylesheet'
      link.rel = 'preload'
      link.as = 'font'
      link.crossOrigin = 'anonymous'
      document.head.appendChild(link3)
      // add font-face to css
      let fontFace3 = `@font-face {
        font-family: ${newVal.fonts.regular_bold_font.font_name};
        src: url(${newVal.fonts.regular_bold_font.font_url});
      }`
      document.styleSheets[0].insertRule(fontFace3, 0)

      // set regular bold font as a css variable
      let regularBoldFontName = newVal.fonts.regular_bold_font.font_name + ', sans-serif'
      document.documentElement.style.setProperty('--regular-bold-font', regularBoldFontName)

      // set regular bold font size_multiplier as a css variable
      let regularBoldFontSizeMultiplier = newVal.fonts.regular_bold_font.size_multiplier
        ? newVal.fonts.regular_bold_font.size_multiplier
        : 1
      document.documentElement.style.setProperty('--regular-bold-font-size-multiplier', regularBoldFontSizeMultiplier)

      const link4 = document.createElement('link')
      link.href = newVal.fonts.button_font ? newVal.fonts.button_font.font_url : newVal.fonts.header_font.font_url
      link.rel = 'stylesheet'
      link.rel = 'preload'
      link.as = 'font'
      link.crossOrigin = 'anonymous'
      document.head.appendChild(link4)
      // add font-face to css
      let fontFace4 = `@font-face {
        font-family: ${
          newVal.fonts.button_font ? newVal.fonts.button_font.font_name : newVal.fonts.header_font.font_name
        };
        src: url(${newVal.fonts.button_font ? newVal.fonts.button_font.font_url : newVal.fonts.header_font.font_url});
      }`
      document.styleSheets[0].insertRule(fontFace4, 0)

      // set button font as a css variable
      let buttonFontName = newVal.fonts.button_font
        ? newVal.fonts.button_font.font_name
        : newVal.fonts.header_font.font_name + ', sans-serif'
      document.documentElement.style.setProperty('--button-font', buttonFontName)

      // set button font size_multiplier as a css variable
      let buttonFontSizeMultiplier =
        newVal.fonts.button_font && newVal.fonts.button_font.size_multiplier
          ? newVal.fonts.button_font.size_multiplier
          : 1
      document.documentElement.style.setProperty('--button-font-size-multiplier', buttonFontSizeMultiplier)
    },
    setStyling(newVal) {
      // set primary color as a css variable
      let primaryColor = newVal.primary_color
      document.documentElement.style.setProperty('--primary-color', primaryColor)

      // set primary text color as a css variable
      let primaryTextColor = newVal.primary_text_color
      document.documentElement.style.setProperty('--primary-text-color', primaryTextColor)

      // set background color as a css variable
      let backgroundColor = newVal.background_color
      document.documentElement.style.setProperty('--background-color', backgroundColor)

      // set passive color as a css variable
      let passiveColor = newVal.passive_color ? newVal.passive_color : '#8f8f8f'
      document.documentElement.style.setProperty('--passive-color', passiveColor)

      // set gray color as a css variable
      let grayColor = newVal.gray_color ? newVal.gray_color : '#EFEFEF'
      document.documentElement.style.setProperty('--gray-color', grayColor)

      // set text color as a css variable
      let textColor = newVal.fonts.regular_font.font_color
      document.documentElement.style.setProperty('--text-color', textColor)

      // set button radius as a css variable
      let buttonRadius = newVal.display_options.button_radius?.enabled ? newVal.display_options.button_radius.radius + 'px' : '5px'
      document.documentElement.style.setProperty('--button-radius', buttonRadius)

      // set header text color as a css variable
      let headerTextColor = newVal.fonts.header_font.font_color
      document.documentElement.style.setProperty('--header-text-color', headerTextColor)

      // set custom green color as a css variable
      let customGreenColor = newVal.display_options.custom_green_color
        ? newVal.display_options.custom_green_color
        : '#17873e'
      document.documentElement.style.setProperty('--custom-green-color', customGreenColor)
    },
    setHeadTags(newVal) {
      // set favicon
      let favicon = newVal.favicon_url
      document.querySelector('link[rel="icon"]').href = favicon

      // set document title
      let title = newVal.title
      document.title = title

      // set meta description
      let metaDescription = newVal.description
      document.querySelector('meta[name="description"]').content = metaDescription

      // set theme color
      let themeColor = newVal.background_color
      document.querySelector('meta[name="theme-color"]').content = themeColor

      // set og image
      let ogImage = newVal.logo_url
      document.querySelector('meta[property="og:image"]').content = ogImage
    },
    setInnerHeight() {
      // set innerHeight as a css variable
      const innerHeight = window.innerHeight
      document.documentElement.style.setProperty('--innerHeight', `${innerHeight}px`)

      // set innerHeight as a css variable on resize
      window.addEventListener('resize', () => {
        const innerHeight = window.innerHeight
        document.documentElement.style.setProperty('--innerHeight', `${innerHeight}px`)
      })

      // set innerHeight as a css variable on screen orientation change
      window.addEventListener('orientationchange', () => {
        const innerHeight = window.innerHeight
        document.documentElement.style.setProperty('--innerHeight', `${innerHeight}px`)
      })
    },
    setInputCss() {
      // set border
      let borderWidth = this.branding?.display_options?.template == 'bento' ? '2px solid #000000' : '1px solid var(--border-color)'
      document.documentElement.style.setProperty('--input-border-width', borderWidth)

      // set active border
      let activeBorderWidth = this.branding?.display_options?.template == 'bento' ? '2px solid var(--primary-color)' : '1px solid var(--primary-color)'
      document.documentElement.style.setProperty('--input-active-border-width', activeBorderWidth)

      // set border radius
      let borderRadius = this.branding?.display_options?.template == 'bento' ? '0px' : '5px'
      document.documentElement.style.setProperty('--input-border-radius', borderRadius)

      // setHeight
      let height = this.branding?.display_options?.template == 'bento' ? '60px' : '44px'
      document.documentElement.style.setProperty('--input-height', height)
    },
    preventScrollWhenModalOpen() {
      // if .modalContainer is in the DOM, add overflow hidden to body, if not add overflow unset.
      let modalContainer = document.querySelector('.modalContainer')
      if (modalContainer) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }

      // if .modalContainer is added to the DOM, add overflow hidden to body, if not add overflow unset.
      document.addEventListener(
        'DOMNodeInserted',
        () => {
          let modalContainer = document.querySelector('.modalContainer')
          if (modalContainer) {
            document.body.style.overflow = 'hidden'
          } else {
            document.body.style.overflow = 'unset'
          }
        },
        false
      )
    },
    checkVersion() {
      let version = '1.1.20'
      // if version is not same as local storage version, clear local storage
      if (localStorage.getItem('version') !== version && localStorage.getItem('version') !== null) {
        this.SET_LOGGED_IN(false)
        this.SET_ORDER_DATA(null)
        localStorage.clear()
        localStorage.setItem('version', version)
        location.reload()
      } else if (localStorage.getItem('version') === null) {
        localStorage.setItem('version', version)
      }
    },
    checkDiscountCode() {
      // if discount code is in query string, save it to local storage to use when creating order
      if (this.$route.query.discount_code) {
        localStorage.removeItem('orderId')
        localStorage.removeItem('dayAndTimeSelected')
        this.SET_DAY_AND_TIME_SELECTED(false)
        this.SET_ORDER_DATA(null)
        localStorage.setItem('discountCode', this.$route.query.discount_code)
      }
    },
  },
  async created() {
    await this.$store.dispatch('getBranding')
    this.$store.dispatch('getStoreConfig')
    this.$store.dispatch('getOrderData')
    this.$store.dispatch('getCustomerData')

    if (localStorage.getItem('selectedStoreId')) {
      this.$store.dispatch('getSelectedStore')
    }
    this.checkVersion()
    this.setInnerHeight()
    this.setInputCss()
    this.preventScrollWhenModalOpen()
    this.checkDiscountCode()
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--regular-font);
}
:root {
  --success-color: #00a6513d;
  --red-color: #e60000;
  --error-color: #fcd1d1;
  --passive-color: #8f8f8f;
  --gray-color: #efefef;
  --passive-color: #8f8f8f;
  --border-color: #e6e3e3;
  --text-color: #000000;
  --header-text-color: #000000;
  --shadow: rgb(0 0 0 / 10%) 0px 4px 16px;
}
.mainView {
  display: flex;
  flex-direction: column;
  height: var(--innerHeight);
  overflow: hidden;
  background-color: var(--background-color);
}
p {
  font-size: calc(14px * var(--regular-font-size-multiplier));
  color: var(--text-color);
  line-height: 1.6;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header-text-color);
  font-family: var(--header-font);
  font-weight: normal;
}
h1 {
  font-size: calc(32px * var(--header-font-size-multiplier));
}
h2 {
  font-size: calc(24px * var(--header-font-size-multiplier));
}
h3 {
  font-size: calc(19px * var(--header-font-size-multiplier));
}
a {
  text-decoration: none;
}
a p {
  font-family: var(--regular-bold-font) !important;
}
button {
  font-size: calc(14px * var(--button-font-size-multiplier));
  border: none;
  border-radius: var(--button-radius);
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  cursor: pointer;
  height: 50px;
  flex-shrink: 0;
  font-family: var(--button-font);
  transition: all 0.2s ease-in-out;
}
button:disabled {
  cursor: not-allowed;
}
button:hover {
  scale: 1.01;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}
input[type='text'],
input[type='password'],
input[type='email'],
input[type='date'],
input[type='number'] {
  border: var(--input-border-width);
  border-radius: var(--input-border-radius);
  padding: 10px;
  font-size: calc(16px * var(--regular-font-size-multiplier));
  height: var(--input-height);
  width: 100%;
  background-color: transparent;
}
input[type='text']:disabled {
  background: var(--border-color);
  cursor: not-allowed;
}
select {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 10px;
  font-size: calc(16px * var(--regular-font-size-multiplier));
  height: 44px;
}
textarea {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 10px;
  font-size: calc(16px * var(--regular-font-size-multiplier));
  resize: none;
  outline: none;
}
textarea:focus {
  border: 1px solid var(--primary-color);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='date'] {
  display: flex;
  display: -webkit-flex;
  flex: 1 0 0;
  -webkit-flex: 1 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 44px;
}
input:active,
input:focus {
  outline: none;
  border: var(--input-active-border-width);
}
label {
  font-size: calc(14px * var(--regular-bold-font-size-multiplier));
  font-family: var(--regular-bold-font);
  color: var(--text-color);
}
.loader {
  content: '';
  width: 32px;
  height: 32px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--passive-color);
  border-radius: 50%;
  animation: loading-spinner 1s ease infinite;
}
.storeLoader {
  content: '';
  width: 32px;
  height: 32px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--passive-color);
  border-radius: 50%;
  animation: loading-spinner 1s ease infinite;
}
@keyframes loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
.mainLoader {
  width: 132px;
  height: 132px;
  border: 7px solid transparent;
  border-top-color: var(--passive-color);
}
.buttonLoader {
  width: 16px;
  height: 16px;
  border: 4px solid transparent;
  border-top-color: var(--primary-text-color);
}
.modalContainer {
  position: fixed;
  top: -500px;
  left: -500px;
  right: -500px;
  bottom: -500px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}
.modalWrapper {
  max-width: 400px;
  width: 92vw;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 80vh;
  overflow: auto;
  margin: 0px 10px;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 50%;
}
.close img {
  width: 17px;
}
.validation {
  font-size: calc(11px * var(--regular-font-size-multiplier));
  color: var(--red-color);
}
.secondaryButton {
  background-color: #ffffff;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.checkboxLabel {
  font-size: calc(16px * var(--regular-font-size-multiplier));
  font-family: var(--regular-font);
}
.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  border: 0.5px solid var(--passive-color);
  padding: 4px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type='checkbox']:active {
  border: none;
}
input[type='checkbox']:checked {
  background-color: var(--primary-color);
  border: none;
}
input[type='checkbox']:checked::after {
  display: block;
}
input[type='checkbox']:before {
  display: none;
}
input[type='checkbox']:after {
  content: url(./assets/icons/check.svg);
  height: 100%;
  display: none;
  scale: 0.7;
}
select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  outline: none;
}
/* For IE10 */
select::-ms-expand {
  display: none;
}
input[type='radio'] {
  accent-color: var(--primary-color);
  height: 22px;
  width: 22px;
}
.feedback {
  width: 100%;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}
.success {
  background-color: var(--success-color);
}
.error {
  background-color: var(--error-color);
}
.radio {
  display: flex;
  gap: 5px;
  border-radius: 7px;
  align-items: center;
  width: 100%;
}
.shake {
  animation: horizontal-shaking 1.5s ease-in-out;
}
@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(5px);
  }
  15% {
    transform: translateX(-5px);
  }
  20% {
    transform: translateX(5px);
  }
  25% {
    transform: translateX(-5px);
  }
  30% {
    transform: translateX(5px);
  }
  35% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  45% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  55% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(5px);
  }
  65% {
    transform: translateX(-5px);
  }
  70% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
  85% {
    transform: translateX(-5px);
  }
  90% {
    transform: translateX(5px);
  }
  95% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
.swiper-button-next,
.swiper-button-prev {
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--primary-color) !important;
  border-radius: 50% !important;
  flex-shrink: 0;
  cursor: pointer;
}
.swiper-button-next {
  right: 0px !important;
}
.swiper-button-prev {
  left: 0px !important;
}
.swiper-button-next::after {
  font-size: 16px !important;
  color: white !important;
  padding-left: 2px !important;
}
.swiper-button-prev::after {
  font-size: 16px !important;
  color: white !important;
  padding-right: 2px !important;
}
.swiper-button-disabled {
  display: none !important;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  scale: 0.9;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  scale: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in;
}
.slide-enter-from,
.slide-leave-to {
  margin-right: -100%;
  opacity: 0;
}
.slide-enter-to,
.slide-leave-from {
  margin-right: 0;
  opacity: 1;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease-in;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
.page-enter-to,
.page-leave-from {
  opacity: 1;
}
.page-enter-active,
.page-leave-active {
  transition: all 0.2s ease-in;
}
.checkbox {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}
.checkbox img {
  width: 12px;
}
.radioSelected {
  width: 16px;
  height: 16px;
  background-color: var(--primary-color);
  border-radius: 50%;
}
.swiper,
.swiper-wrapper {
  z-index: unset !important;
}

@media screen and (max-width: 500px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}
</style>
