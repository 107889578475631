import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as FullStory from '@fullstory/browser'
import { createGtm } from '@gtm-support/vue-gtm'

FullStory.init({ orgId: 'o-1GATH8-na1' })

const app = createApp(App).use(store).use(router)

if (process.env.VUE_APP_GTM_ID) {
  app.use(
    createGtm({
      id: process.env.VUE_APP_GTM_ID,
      enabled: true,
      debug: false,
      vueRouter: router,
    })
  )
}

app.config.globalProperties.$FullStory = FullStory

app.mount('#app')
