<template>
  <div @click.self="closeModal" class="modalContainer">
    <div
      class="modalWrapper"
      :style="{
        borderRadius: branding?.display_options?.template == 'bento' ? '0px' : '5px',
        border: branding?.display_options?.template == 'bento' ? '2px solid #000000' : 'none',
      }"
    >
      <div @click="closeModal" class="close">
        <img src="@/assets/icons/close.svg" alt="close" />
      </div>
      <img class="logo" :src="getMenuLogo" alt="companyLogo" />
      <div v-if="loading" class="loaderWrapper">
        <div class="loader"></div>
      </div>
      <div class="contentWrapper" v-if="!loading">
        <h1>{{ header }}</h1>
        <h3>{{ message }}</h3>
        <button @click="closeModal">CLOSE</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import customersApi from '@/api/customersApi'

export default {
  data() {
    return {
      message: 'You’ve successfully earned points through your purchase',
      header: 'Congratulations!',
      loading: true,
    }
  },
  computed: {
    ...mapState(['branding', 'customerData', 'storeConfig', 'cookies', 'selectedStore', 'copperDetails']),
    getMenuLogo() {
      return this.branding.menu_logo_url
    },
  },
  watch: {
    selectedStore(newVal, oldVal) {
      if (oldVal == null && newVal != null) {
        this.processCopperPoints()
      }
    },
  },
  methods: {
    ...mapMutations(['SET_COPPER_DETAILS']),
    closeModal() {
      this.$emit('closeModal')
    },
    processCopperPoints() {
      let customerId = localStorage.getItem('customerId')
      let chainName = localStorage.getItem('chainName')
      let payload = {
        params: {
          url: this.branding.api_base_url + '/incoming/eef3acaf-170f-4270-8235-4b9e953eff20?synchronous=1',
          method: 'POST',
        },
        headers: {
          service_id: '38e738f2-ac3c-11ed-8a64-001a6436ce70',
          'Copper-Restaurant-External-ID': 1,
        },
        cookies: this.cookies,
        event_type: 'order_redeem_request',
        chain: chainName,
        copperItem_id: 'cd98b5a0-5698-4eb8-a530-b89906b31536',
        ticket_id: this.copperDetails.ticket,
        store_id: this.$route.params.storeId,
        loyalty_identifier: customerId,
        rest_id: this.selectedStore.restaurant_id,
      }
      customersApi
        .collectLoyaltyInStore(payload)
        .then((response) => {
          this.loading = false
          if (response?.data?.data?.error) {
            this.header = 'Error'
            this.message = response?.data?.data?.error?.message
            return
          } else {
            this.header = 'Success'
            this.message = 'You’ve successfully earned points through your purchase'
          }
          this.SET_COPPER_DETAILS(null)
        })
        .catch((error) => {
          this.loading = false
          this.header = 'Error'
          this.message = error.response?.data?.data
          this.SET_COPPER_DETAILS(null)
          console.log(error)
        })
    },
  },
  mounted() {
    if (this.selectedStore) {
      this.processCopperPoints()
    }
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.closeModal()
      }
    })
  },
  beforeUnmount() {
    localStorage.removeItem('copperDetails')
    document.removeEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.closeModal()
      }
    })
  },
}
</script>

<style scoped>
.modalContainer {
  z-index: 9999;
}
.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
button,
.textWrapper {
  width: 100%;
  margin-top: 20px;
}
.modalWrapper h1 {
  margin-bottom: 20px;
}
.modalWrapper h3 {
  text-align: center;
}
.logo {
  width: 80px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.loaderWrapper {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
