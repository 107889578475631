import moment from 'moment'
import store from '../store'

export default {
  methods: {
    formatPhoneNumber(phoneNumber) {
      let cleaned = ('' + phoneNumber).replace(/\D/g, '')
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    },
    formatPhoneNumberWhileTyping(number) {
      let phone = number
      phone = phone.replace(/\D/g, '')
      phone = phone.substring(0, 10)
      var size = phone.length
      if (size == 0) {
        phone = phone + ''
      } else if (size < 4) {
        phone = '(' + phone
      } else if (size < 7) {
        phone = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6)
      } else {
        phone = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6, 10)
      }
      if (this.customerInfo) this.customerInfo.phone = phone
      if (this.signUpInfo) this.signUpInfo.phone = phone
      if (this.addressInputs) this.addressInputs.phone = phone
      if (this.guestInfo) this.guestInfo.phone = phone
      if (this.phone) this.phone = phone
      return phone
    },
    formatPrice(price) {
      if (price == null) return ''
      let formattedPrice = price / 100
      let priceType = 'plus'
      if (formattedPrice.toString().charAt(0) === '-') {
        priceType = 'minus'
        formattedPrice = formattedPrice.toString().slice(1)
        formattedPrice = parseFloat(formattedPrice)
      }
      let currency = store.state.storeConfig ? store.state.storeConfig.localization.currency_symbol : '$'
      formattedPrice = formattedPrice.toFixed(2)
      if (priceType == 'minus') {
        return '-' + currency + formattedPrice
      }
      return currency + formattedPrice
    },
    formatDate(date, showHour) {
      if (showHour) return moment(date).format('MMM D, YYYY, h:mm A')
      return moment(date).format('MMM D, YYYY')
    },
  },
}
