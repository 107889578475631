<template>
  <div @click.self="closeModal" class="modalContainer" data-cy="forgot-password-modal">
    <div
      class="modalWrapper"
      :style="{
        borderRadius: branding?.display_options?.template == 'bento' ? '0px' : '5px',
        border: branding?.display_options?.template == 'bento' ? '2px solid #000000' : 'none',
      }"
    >
      <div @click="closeModal" class="close" data-cy="close-forgot-password-modal">
        <img src="@/assets/icons/close.svg" alt="close" />
      </div>
      <a @click="backToLogin" class="back" data-cy="back-to-login-link">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.521 415.783">
          <path
            id="Path_19208"
            data-name="Path 19208"
            d="M460.94,81.871a33.6,33.6,0,0,0-47.546,0l-174.33,174.39a33.6,33.6,0,0,0,0,47.484l174.39,174.39A33.6,
              33.6,0,0,0,461,430.647L310.3,280.007l150.64-150.64a33.605,33.605,0,0,0,0-47.488Z"
            transform="translate(-229.24 -72.016)"
          />
        </svg>
        <p>BACK TO LOGIN</p>
      </a>
      <img class="logo" :src="getMenuLogo" alt="companyLogo" />
      <h2>Forgot Your Password?</h2>
      <div class="signUp">
        <p>Don't have an account?</p>
        <a @click="signUpClicked" href="javascript:;" data-cy="create-account-link"> CREATE AN ACCOUNT </a>
      </div>
      <div class="inputWrapper">
        <label for="email">Email</label>
        <input
          @keyup.enter="resetPassword"
          v-model="forgotPassword.email"
          type="email"
          placeholder="Your Email Address"
          id="email"
          data-cy="forgot-password-email-input"
        />
        <p v-if="v$.forgotPassword.email.$error === true" class="validation" data-cy="email-validation-error">
          Please enter your email address
        </p>
      </div>
      <p class="feedback success" v-if="emailSent" data-cy="password-reset-success-message">
        Password reset email sent
      </p>
      <p class="feedback error" v-if="error.status" data-cy="password-reset-error-message">{{ error.message }}</p>
      <button @click="resetPassword" :disabled="buttonLoader" data-cy="reset-password-button">
        <div v-if="buttonLoader" class="loader buttonLoader"></div>
        <template v-if="!buttonLoader">RESET PASSWORD</template>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import modalMixins from '@/mixins/modalMixins.js'

import customersApi from '@/api/customersApi.js'

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      forgotPassword: {
        email: '',
      },
      emailSent: false,
      error: {
        status: false,
        message: '',
      },
    }
  },
  validations() {
    return {
      forgotPassword: {
        email: { required, email },
      },
    }
  },
  mixins: [modalMixins],
  computed: {
    ...mapState(['buttonLoader', 'branding', 'cookies']),
    getMenuLogo() {
      return this.branding.menu_logo_url
    },
  },
  methods: {
    ...mapMutations(['SET_BUTTON_LOADER', 'SET_COOKIES']),
    resetPassword() {
      this.v$.$touch()
      if (this.v$.$error) {
        return
      }
      this.emailSent = false
      this.error.status = false
      this.error.message = ''
      this.SET_BUTTON_LOADER(true)

      let payload = {
        email: this.forgotPassword.email,
        reset: true,
      }

      customersApi
        .resetPassword(payload)
        .then((response) => {
          if (response.data.data.ok) {
            this.emailSent = true
          } else {
            this.error.status = true
            this.error.message = 'Email could not be sent'
          }
          this.SET_BUTTON_LOADER(false)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch(() => {
          this.SET_BUTTON_LOADER(false)
          this.error.status = true
          this.error.message = 'Something went wrong. Please try again'
        })
    },
    closeModal() {
      this.$emit('closeModal')
    },
    signUpClicked() {
      this.$emit('closeModal')
      this.$emit('signUpClicked')
    },
    backToLogin() {
      this.$emit('closeModal')
      this.$emit('backToLogin')
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById('email').focus()
    })
  },
}
</script>

<style scoped>
.logo {
  width: 80px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signUp {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 15px 0px 20px 0px;
  flex-wrap: wrap;
  justify-content: center;
}
.signUp a {
  font-size: calc(14px * var(--regular-bold-font-size-multiplier));
  font-family: var(--regular-bold-font);
  color: var(--primary-color);
  cursor: pointer;
}
.inputWrapper {
  width: 100%;
  margin-bottom: 10px;
}
button {
  width: 100%;
  margin-top: 20px;
}
.back {
  cursor: pointer;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
}
.back svg {
  width: 8px;
  margin-right: 5px;
  fill: var(--primary-color);
}
.back p {
  font-size: calc(14px * var(--regular-bold-font-size-multiplier));
  color: var(--primary-color);

  display: flex;
  align-items: center;
}
</style>
