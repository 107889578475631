<template>
  <div
    :style="{
      'background-color': showLoader ? 'rgba(0, 0, 0, 0.5)' : 'var(--background-color)',
    }"
    class="loading"
  >
    <div class="loadingWrapper">
      <div
        :style="{
          'border-top-color': showLoader ? '#FFFFFF' : 'var(--passive-color)',
        }"
        class="loader mainLoader"
      ></div>
      <img v-if="this.branding" :src="getMenuLogo" alt="companyLogo" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['showLoader'],
  computed: {
    ...mapState(['branding']),
    getMenuLogo() {
      if (this.branding?.display_options.loading_logo_url) {
        return this.branding.display_options.loading_logo_url
      }
      return this.branding ? this.branding.menu_logo_url : ''
    },
  },
}
</script>

<style scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingWrapper {
  position: relative;
}
.loadingWrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
}
</style>
