<template>
  <div class="selector">
    <div :style="{ cursor: elements.length == 1 ? 'default' : loading ? 'not-allowed' : 'pointer' }" class="elements">
      <div class="active" :style="{ left: leftAmount, width: 100 / elements.length + '%' }"></div>
      <p
        v-for="(element, index) in elements"
        :key="index"
        @click="change(element)"
        :style="{
          'pointer-events': elements.length == 1 || loading ? 'none' : 'auto',
          'background-color': elements.length == 1 ? 'var(--gray-color)' : '',
        }"
        :class="{
          activeText: selectedId == element[elementId] && elements.length != 1,
        }"
      >
        {{ element[elementName] }}
        {{ elements.length == 1 ? 'Only' : '' }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['elements', 'elementId', 'selectedId', 'elementName', 'loading'],
  computed: {
    leftAmount() {
      let elementsLength = this.elements.length
      let multiplier = 100 / elementsLength
      let selectedIndex = this.elements.findIndex((element) => element[this.elementId] == this.selectedId)
      let leftAmount = multiplier * selectedIndex
      return leftAmount + '%'
    },
  },
  methods: {
    change(element) {
      this.$emit('change', element)
    },
  },
}
</script>

<style scoped>
.selector {
  background-color: var(--gray-color);
  border-radius: 5px;
  padding: 5px;
}
.elements {
  display: flex;
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.elements p {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(15px * var(--regular-font-size-multiplier));
  border-radius: var(--button-radius);
  text-align: center;
  line-height: 1;
  z-index: 2;
}
.active {
  background-color: var(--primary-color) !important;
  flex: 1;
  position: absolute;
  height: 100%;
  border-radius: var(--button-radius);
  transition: all 0.3s ease-in-out;
}
.activeText {
  font-family: var(--regular-bold-font);
  color: var(--primary-text-color);
}
</style>
