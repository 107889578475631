<template>
  <div @click.self="closeModal" class="modalContainer">
    <div
      class="modalWrapper"
      :style="{
        borderRadius: branding?.display_options?.template == 'bento' ? '0px' : '5px',
        border: branding?.display_options?.template == 'bento' ? '2px solid #000000' : 'none',
      }"
    >
      <div @click="closeModal" class="close">
        <img src="@/assets/icons/close.svg" alt="close" />
      </div>
      <h2>{{ phoneInputVisible ? 'Change Phone' : 'Verify Phone' }}</h2>
      <p v-if="phoneInputVisible" class="description">
        To change your phone number, enter your phone number and click update. To go back
        <span class="clickHere" @click="togglePhoneInput">CLICK HERE</span>
      </p>
      <p v-else class="description">
        Please enter the PIN sent to
        <span class="bold">{{ formatPhoneNumber(customerData.phone) }}</span>
        below to validate your account. If you want to change your phone number
        <span class="clickHere" @click="togglePhoneInput">CLICK HERE</span>
      </p>
      <div v-if="phoneInputVisible" class="phoneChange">
        <div class="inputWrapper">
          <label for="phone">Cell Phone Number</label>
          <input
            @keyup="formatPhoneNumberWhileTyping(phone)"
            v-model="phone"
            type="text"
            placeholder="Cell Phone Number"
            id="phone"
            inputmode="tel"
          />
          <p v-if="v$.phone.$error === true" class="validation">Please enter your cell phone number</p>
        </div>
        <p class="feedback error" v-if="error.status && phoneInputVisible">
          {{ error.message }}
        </p>
        <button @click="changePhone">
          <div v-if="buttonLoader" class="loader buttonLoader"></div>
          <template v-if="!buttonLoader">UPDATE</template>
        </button>
      </div>
      <div v-if="!phoneInputVisible" class="pin">
        <Verification
          type="text"
          activeInputClass="active-input-class"
          @on-complete="onCompleteHandler"
          @on-paste="onPasteHandler"
        />
      </div>
      <p v-if="!codeIsValid && !phoneInputVisible" class="validation">Invalid pin code</p>
      <a @click="sendVerificationCode(true)" class="resend" href="javascript:;" v-if="!phoneInputVisible">
        <p>RESEND PIN</p>
      </a>
      <p class="feedback error" v-if="error.status && !phoneInputVisible">
        {{ error.message }}
      </p>
      <button class="verify" @click="verifyPhone" :disabled="pinLoading" v-if="!phoneInputVisible">
        <div v-if="pinLoading" class="loader buttonLoader"></div>
        <template v-if="!pinLoading"> VERIFY </template>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import formattingMixins from '@/mixins/formattingMixins'

import Verification from './Verification.vue'

import modalMixins from '@/mixins/modalMixins.js'

import customersApi from '@/api/customersApi.js'

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      pin: '',
      phone: '',
      phoneInputVisible: false,
      codeIsValid: true,
      error: {
        status: false,
        message: '',
      },
      pinLoading: false,
    }
  },
  components: {
    Verification,
  },
  validations() {
    return {
      phone: { required },
    }
  },
  mixins: [modalMixins, formattingMixins],
  computed: {
    ...mapState(['customerData', 'buttonLoader', 'cookies', 'branding']),
  },
  methods: {
    ...mapMutations(['SET_TOASTER', 'SET_CUSTOMER_DATA', 'SET_PHONE_VERIFIED', 'SET_BUTTON_LOADER', 'SET_COOKIES']),
    togglePhoneInput() {
      this.error.status = false
      this.phone = ''
      this.phoneInputVisible = !this.phoneInputVisible
    },
    checkValidation() {
      return Object.values(this.pin).join('').length === 6
    },
    closeModal() {
      this.$emit('closeModal')
    },
    onCompleteHandler(e) {
      this.pin = e
    },
    onPasteHandler(e) {
      this.pin = e
    },
    sendVerificationCode(resend) {
      let payload = {
        phone: this.customerData.phone,
      }
      customersApi
        .sendVerificationCode(payload)
        .then((response) => {
          if (response.data.data.ok) {
            if (resend) {
              this.error.status = true
              this.error.message = 'Verification PIN sent again'
            }
          }
          this.SET_COOKIES(response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
          this.error.status = true
          this.error.message = error.response.data.data.error
        })
    },
    verifyPhone() {
      if (this.pin.length != 6) {
        this.codeIsValid = false
        return
      }
      this.pinLoading = true
      this.error.status = false
      this.error.message = ''

      let payload = {
        phone: this.customerData.phone,
        security_code: this.pin,
      }

      customersApi
        .verifyPhone(payload)
        .then((response) => {
          if (response.data.data.ok) {
            this.SET_PHONE_VERIFIED(true)
            localStorage.setItem('phoneVerified', true)
            this.getCustomerInfo()
          } else if (!response.data.data.ok) {
            this.pinLoading = false
            this.error.status = true
            this.error.message = 'Verification failed. Please try again.'
          }
          this.SET_COOKIES(response.data.cookies)
        })
        .catch((error) => {
          this.pinLoading = false
          this.error.status = true
          this.error.message = error.response.data.data.error
        })
    },
    getCustomerInfo() {
      customersApi
        .getCustomerInfo()
        .then((response) => {
          this.SET_CUSTOMER_DATA(response.data.data)
          this.pinLoading = false
          this.$emit('closeModal')
          let toasterData = {
            show: true,
            message: 'Phone number verified',
          }
          this.SET_TOASTER(toasterData)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch(() => {
          this.pinLoading = false
          this.loading = false
          this.error.status = true
          this.error.message = 'Something went wrong. Please try again'
        })
    },
    changePhone() {
      // check if all fields are valid
      this.v$.$touch()
      if (this.v$.$error) {
        return
      }
      this.SET_BUTTON_LOADER(true)

      let customerID = localStorage.getItem('customerId')
      let payload = {
        email: this.customerData.email,
        first_name: this.customerData.first_name,
        last_name: this.customerData.last_name,
        // remove all non-digits before sending to the api
        phone: this.phone.replace(/\D/g, ''),
        birthdate: this.customerData.birthdate,
        email_optin: false,
      }

      customersApi
        .updateCustomer(payload, customerID)
        .then((response) => {
          this.SET_CUSTOMER_DATA(response.data.data)
          this.SET_BUTTON_LOADER(false)
          this.SET_COOKIES(response.data.cookies)
          let toasterData = {
            show: true,
            message: 'Verification code sent to the new phone number',
          }
          this.SET_TOASTER(toasterData)
          this.sendVerificationCode()
          this.togglePhoneInput()
        })
        .catch((error) => {
          console.log(error)
          if (error.response.data.status == 403) {
            let toasterData = {
              show: true,
              message: 'Your session has expired. Please log in again',
            }
            this.SET_TOASTER(toasterData)
            this.$emit('logout')
          }
          this.SET_BUTTON_LOADER(false)
          this.error.status = true
          this.error.message = error.response.data.data.error
        })
    },
  },
  created() {
    this.sendVerificationCode()
  },
}
</script>

<style scoped>
.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pin {
  display: flex;
  gap: 10px;
  margin: 20px 0px 10px 0px;
}
.pin input {
  flex: 1;
  width: 50px;
  height: 70px;
  text-align: center;
  font-size: calc(30px * var(--regular-font-size-multiplier));
}
.bold {
  font-family: var(--regular-bold-font);
}
.description {
  text-align: center;
  margin-top: 10px;
}
.resend {
  margin-top: auto;

  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
}
.resend p {
  color: var(--primary-color);
}
.verify {
  width: 100%;
  margin-top: 10px;
}
.error {
  margin: 10px 0px;
}
.phoneChange {
  display: flex;
  gap: 15px;
  width: 100%;
  margin-top: 15px;
  flex-direction: column;
}
.phoneChange .inputWrapper {
  flex: 1;
}
.clickHere {
  cursor: pointer;
  font-family: var(--regular-bold-font);
  color: var(--primary-color);
}

@media screen and (max-width: 768px) {
  .pin input {
    width: 40px;
    height: 60px;
    font-size: calc(25px * var(--regular-font-size-multiplier));
  }
}
</style>
