<template>
  <div @click.self="closeModal" class="modalContainer" data-cy="create-account-modal">
    <div
      class="modalWrapper"
      :style="{
        borderRadius: branding?.display_options?.template == 'bento' ? '0px' : '5px',
        border: branding?.display_options?.template == 'bento' ? '2px solid #000000' : 'none',
      }"
    >
      <div @click="closeModal" class="close" data-cy="close-create-account-modal">
        <img src="@/assets/icons/close.svg" alt="close" />
      </div>
      <img class="logo" :src="getMenuLogo" alt="companyLogo" />
      <h2 style="text-align: center">Create Your Account</h2>
      <div class="login">
        <p>Already have an account?</p>
        <a @click="loginClicked" href="javascript:;"> LOGIN </a>
      </div>
      <div class="nameWrapper">
        <div class="inputWrapper">
          <label for="firstName">First Name</label>
          <input
            @keyup.enter="signUp"
            v-model="signUpInfo.firstName"
            type="text"
            placeholder="First Name"
            id="firstName"
            data-cy="first-name-input"
          />
          <p v-if="v$.signUpInfo.firstName.$error === true" class="validation" data-cy="first-name-validation-error">
            Please enter your first name
          </p>
        </div>
        <div class="inputWrapper">
          <label for="lastName">Last Name</label>
          <input
            @keyup.enter="signUp"
            v-model="signUpInfo.lastName"
            type="text"
            placeholder="Last Name"
            id="lastName"
            data-cy="last-name-input"
          />
          <p v-if="v$.signUpInfo.lastName.$error === true" class="validation" data-cy="last-name-validation-error">
            Please enter your last name
          </p>
        </div>
      </div>
      <div class="inputWrapper">
        <label for="email">Email</label>
        <input
          @keyup.enter="signUp"
          v-model="signUpInfo.email"
          type="email"
          placeholder="Email"
          id="email"
          inputmode="email"
          data-cy="create-account-email-input"
        />
        <p v-if="v$.signUpInfo.email.$error === true" class="validation" data-cy="email-validation-error">
          Please enter your email address
        </p>
      </div>
      <div class="inputWrapper">
        <label for="birthday">Birthday</label>
        <input
          @keyup.enter="signUp"
          @keyup="formatBirthdateWhileTyping"
          v-model="signUpInfo.birthdate"
          type="text"
          placeholder="mm/dd/yyyy"
          id="birthday"
          inputmode="numeric"
          data-cy="birthday-input"
        />
        <!-- <p v-if="v$.signUpInfo.birthdate.$error === true" class="validation" data-cy="birthday-validation-error">
          Please enter your birthday
        </p> -->
        <p class="info">So we can offer you special disounts on your birthday</p>
      </div>
      <div class="inputWrapper">
        <label for="phone">Cell Phone Number</label>
        <input
          @keyup.enter="signUp"
          @keyup="formatPhoneNumberWhileTyping(signUpInfo.phone)"
          v-model="signUpInfo.phone"
          type="text"
          placeholder="Cell Phone Number"
          id="phone"
          inputmode="tel"
          data-cy="phone-input"
        />
        <p v-if="v$.signUpInfo.phone.$error === true" class="validation" data-cy="phone-validation-error">
          Please enter your cell phone number
        </p>
        <p class="info">To verify your account, we'll send a 6 digit code via SMS to this number.</p>
      </div>
      <div class="inputWrapper">
        <label for="password">Password</label>
        <input
          @keyup.enter="signUp"
          v-model="signUpInfo.password"
          :type="passwordInputType"
          placeholder="Password"
          id="password"
          data-cy="create-account-password-input"
        />
        <p
          v-if="
            v$.signUpInfo.password.$errors.length != 0 && v$.signUpInfo.password.$errors[0].$validator == 'required'
          "
          class="validation"
          data-cy="password-validation-error"
        >
          Please enter your password
        </p>
        <p v-if="passwordIsValid === false" class="validation" data-cy="password-length-validation-error">
          Please enter a valid password
        </p>
        <a @click="togglePassword" class="passwordToggle" href="#">
          <img :src="passwordIconPath" alt="password" />
        </a>
        <PasswordValidator :password="signUpInfo.password" @valid="checkIfPasswordValid" />
      </div>
      <div class="consent">
        <p>
          By creating an account, you agree to <br />
          Lunchbox's
          <span><a href="https://lunchbox.io/terms" target="blank_">Terms of Service</a></span>
          &
          <span><a href="https://lunchbox.io/privacy" target="blank_">Privacy Policy</a></span>
        </p>
      </div>
      <p class="feedback error" v-if="error.status">{{ error.message }}</p>
      <button @click="signUp" :disabled="buttonLoader" data-cy="create-account-button">
        <div v-if="buttonLoader" class="loader buttonLoader"></div>
        <template v-if="!buttonLoader">SIGN UP</template>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

import { mapState, mapMutations } from 'vuex'

import modalMixins from '@/mixins/modalMixins.js'
import formattingMixins from '@/mixins/formattingMixins'

import customersApi from '@/api/customersApi.js'

import PasswordValidator from '@/components/Elements/PasswordValidator.vue'

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      signUpInfo: {
        firstName: this.firstName || '',
        lastName: this.lastName || '',
        email: this.email || '',
        phone: this.formatPhoneNumber(this.phone) || '',
        password: '',
        birthdate: '',
      },
      passwordInputType: 'password',
      passwordIconPath: require('@/assets/icons/passwordShow.svg'),
      error: {
        status: false,
        message: '',
      },
      passwordIsValid: null,
    }
  },
  validations() {
    return {
      signUpInfo: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phone: { required },
        /* birthdate: { required }, */
        password: {
          required,
          minLength: minLength(6),
        },
      },
    }
  },
  components: {
    PasswordValidator,
  },
  props: ['email', 'phone', 'firstName', 'lastName'],
  mixins: [modalMixins, formattingMixins],
  computed: {
    ...mapState(['buttonLoader', 'branding', 'cookies', 'storeConfig', 'copperDetails']),
    getMenuLogo() {
      return this.branding.menu_logo_url
    },
  },
  methods: {
    ...mapMutations(['SET_LOGGED_IN', 'SET_BUTTON_LOADER', 'SET_CUSTOMER_DATA', 'SET_PHONE_VERIFIED', 'SET_COOKIES']),
    closeModal() {
      this.$emit('closeModal')
    },
    toggleCopperInfo() {
      this.$emit('toggleCopperInfo')
    },
    loginClicked() {
      this.$emit('closeModal')
      this.$emit('loginClicked')
    },
    checkIfPasswordValid(valid) {
      this.passwordIsValid = valid
    },
    signUp() {
      this.v$.$touch()
      this.checkIfPasswordValid(this.passwordIsValid)
      if (this.v$.$error || this.passwordIsValid === null || this.passwordIsValid === false) {
        return
      }
      this.error.status = false
      this.error.message = ''
      this.SET_BUTTON_LOADER(true)

      let formattedBirthdate = ''
      if (this.signUpInfo.birthdate != '') {
        // check if birthdate matches MM/DD/YYYY
        if (this.signUpInfo.birthdate.length > 0 && !moment(this.signUpInfo.birthdate, 'MM/DD/YYYY', true).isValid()) {
          this.error.status = true
          this.error.message = 'Birthday must be in MM/DD/YYYY format'
          this.SET_BUTTON_LOADER(false)
          return
        }

        // get todays date
        let todaysDate = moment().format('YYYY-MM-DD')
        // check if birthdate is after todaysDate
        if (
          this.signUpInfo.birthdate.length > 0 &&
          moment(this.signUpInfo.birthdate, 'MM/DD/YYYY').isAfter(todaysDate, 'YYYY-MM-DD')
        ) {
          this.error.status = true
          this.error.message = 'Please enter a valid birthdate'
          this.SET_BUTTON_LOADER(false)
          return
        }

        // get the date from 13 years ago
        let maxDate = moment().subtract(13, 'years').format('YYYY-MM-DD')
        // check if birthdate is after maxDate
        if (
          this.signUpInfo.birthdate.length > 0 &&
          moment(this.signUpInfo.birthdate, 'MM/DD/YYYY').isAfter(maxDate, 'YYYY-MM-DD')
        ) {
          this.error.status = true
          this.error.message = 'You must be at least 13 years old to sign up'
          this.SET_BUTTON_LOADER(false)
          return
        }

        // check if phone number is valid
        if (this.signUpInfo.phone.length > 0 && this.signUpInfo.phone.length < 14) {
          this.error.status = true
          this.error.message = 'Please enter a valid phone number'
          this.SET_BUTTON_LOADER(false)
          return
        }

        if (this.signUpInfo.birthdate.length > 0) {
          // change birthdate format from mm/dd/yyyy to yyyy-mm-dd
          let splittedBirthdate = this.signUpInfo.birthdate.split('/')
          formattedBirthdate = `${splittedBirthdate[2]}-${splittedBirthdate[0]}-${splittedBirthdate[1]}`
        }
      }

      let payload = {
        email: this.signUpInfo.email,
        password: this.signUpInfo.password,
        first_name: this.signUpInfo.firstName,
        last_name: this.signUpInfo.lastName,
        phone: this.signUpInfo.phone.replace(/\D/g, ''),
        auto_login: true,
        email_optin: true,
        birthdate: formattedBirthdate,
      }

      if (this.signUpInfo.birthdate == '') {
        delete payload.birthdate
      }

      customersApi
        .createAccount(payload)
        .then((response) => {
          this.SET_CUSTOMER_DATA(response.data.data)
          this.SET_PHONE_VERIFIED(response.data.data.is_phone_verified)
          localStorage.setItem('phoneVerified', response.data.data.is_phone_verified)
          this.SET_COOKIES(response.data.cookies)
          this.login()
        })
        .catch((error) => {
          this.SET_BUTTON_LOADER(false)
          this.error.status = true
          if (error.response.data.data && error.response.data.data.error) {
            this.error.message = error.response.data.data.error
          } else {
            this.error.message = 'Something went wrong. Please try again.'
          }
        })
    },
    login() {
      let payload = {
        username: this.signUpInfo.email,
        password: this.signUpInfo.password,
      }

      customersApi
        .login(payload)
        .then((response) => {
          this.SET_LOGGED_IN(true)
          localStorage.setItem('customerId', response.data.data.customer_id)
          localStorage.setItem('auth', 'Basic ' + response.data.data.auth_cookie)
          if (this.storeConfig.require_phone_verification) {
            this.$emit('accountCreated')
          }
          if (this.copperDetails) {
            this.toggleCopperInfo()
          }
          this.closeModal()
          this.SET_BUTTON_LOADER(false)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch(() => {
          this.SET_BUTTON_LOADER(false)
          this.error.status = true
          this.error.message = 'Something went wrong. Please try again.'
        })
    },
    formatBirthdateWhileTyping(event) {
      let birthdate = this.signUpInfo.birthdate
      if (event.keyCode === 8 || event.keyCode === 46) {
        this.signUpInfo.birthdate = birthdate
        return
      }
      if (birthdate.length === 2 || birthdate.length === 5) {
        this.signUpInfo.birthdate = birthdate + '/'
      }
      if (birthdate.length > 10) {
        this.signUpInfo.birthdate = birthdate.slice(0, 10)
      }
    },
    togglePassword() {
      if (this.passwordInputType == 'password') {
        this.passwordInputType = 'text'
        this.passwordIconPath = require('@/assets/icons/passwordHide.svg')
      } else {
        this.passwordInputType = 'password'
        this.passwordIconPath = require('@/assets/icons/passwordShow.svg')
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById('firstName').focus()
    })
  },
}
</script>

<style scoped>
.modalContainer {
  z-index: 9999;
}
.logo {
  width: 80px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 15px 0px 20px 0px;
}
.login a {
  font-size: calc(14px * var(--regular-bold-font-size-multiplier));
  font-family: var(--regular-bold-font);
  color: var(--primary-color);
  cursor: pointer;
}
.inputWrapper {
  width: 100%;
  margin-bottom: 10px;
}
button {
  width: 100%;
  margin-top: 20px;
}
.consent {
  display: flex;
  align-items: center;
}
.consent p {
  text-align: center;
}
.consent a {
  color: var(--primary-color);
  font-family: var(--regular-bold-font);
}
.nameWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
}
.passwordToggle {
  position: absolute;
  right: 10px;
  top: 35px;
}
.info {
  font-size: calc(12px * var(--regular-font-size-multiplier));
  color: var(--passive-color);
}
.feedback {
  margin-top: 15px;
}

@media screen and (max-width: 768px) {
  .nameWrapper {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
@media screen and (max-width: 500px) {
  .validations {
    grid-template-columns: 1fr;
  }
}
</style>
