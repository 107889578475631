<template>
  <div @click.self="closeModal" class="modalContainer" data-cy="login-modal">
    <div
      class="modalWrapper"
      :style="{
        borderRadius: branding?.display_options?.template == 'bento' ? '0px' : '5px',
        border: branding?.display_options?.template == 'bento' ? '2px solid #000000' : 'none',
      }"
    >
      <div @click="closeModal" class="close" data-cy="close-login-modal">
        <img src="@/assets/icons/close.svg" alt="close" />
      </div>
      <img class="logo" :src="getMenuLogo" alt="companyLogo" />
      <h2 style="text-align: center">Login To Your Account</h2>
      <div class="signUp">
        <p>Don't have an account?</p>
        <a @click="signUpClicked" href="javascript:;" data-cy="create-account-link"> CREATE AN ACCOUNT </a>
      </div>
      <div class="inputWrapper">
        <label for="email">Email</label>
        <input
          @keyup.enter="login"
          v-model="loginInfo.email"
          type="email"
          placeholder="Your Email Address"
          id="email"
          inputmode="email"
          data-cy="email-input"
        />
        <p v-if="v$.loginInfo.email.$error === true" class="validation" data-cy="email-validation-error">
          Please enter your email address
        </p>
      </div>
      <div class="inputWrapper">
        <label for="password">Password</label>
        <input
          @keyup.enter="login"
          v-model="loginInfo.password"
          :type="passwordInputType"
          placeholder="Your Password"
          id="password"
          data-cy="password-input"
        />
        <p v-if="v$.loginInfo.password.$error === true" class="validation" data-cy="password-validation-error">
          Please enter your password
        </p>
        <a @click="togglePassword" class="passwordToggle" href="#" data-cy="toggle-password-visibility">
          <img :src="passwordIconPath" alt="password" />
        </a>
      </div>
      <a @click="forgotPasswordClicked" class="forgotPassword" href="javascript:;" data-cy="forgot-password-link">
        FORGOT PASSWORD?
      </a>
      <p class="feedback error" v-if="error.status" data-cy="error-message">{{ error.message }}</p>
      <button @click="login" :disabled="buttonLoader" data-cy="login-button">
        <div v-if="buttonLoader" class="loader buttonLoader"></div>
        <template v-if="!buttonLoader">LOGIN</template>
      </button>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import { mapState, mapMutations } from 'vuex'

import modalMixins from '@/mixins/modalMixins.js'

import customersApi from '@/api/customersApi.js'

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      loginInfo: {
        email: '',
        password: '',
      },
      passwordInputType: 'password',
      passwordIconPath: require('@/assets/icons/passwordShow.svg'),
      error: {
        status: false,
        message: '',
      },
    }
  },
  validations() {
    return {
      loginInfo: {
        email: { required, email },
        password: { required },
      },
    }
  },
  mixins: [modalMixins],
  computed: {
    ...mapState(['buttonLoader', 'branding', 'cookies', 'copperDetails']),
    getMenuLogo() {
      return this.branding.menu_logo_url
    },
  },
  methods: {
    ...mapMutations([
      'SET_LOGGED_IN',
      'SET_BUTTON_LOADER',
      'SET_CUSTOMER_DATA',
      'SET_TOASTER',
      'SET_PHONE_VERIFIED',
      'SET_COOKIES',
    ]),
    toggleCopperInfo() {
      this.$emit('toggleCopperInfo')
    },
    login() {
      this.v$.$touch()
      if (this.v$.$error) {
        return
      }
      this.error.status = false
      this.error.message = ''
      this.SET_BUTTON_LOADER(true)

      let payload = {
        username: this.loginInfo.email,
        password: this.loginInfo.password,
      }

      customersApi
        .login(payload)
        .then((response) => {
          localStorage.setItem('customerId', response.data.data.customer_id)
          localStorage.setItem('auth', 'Basic ' + response.data.data.auth_cookie)
          this.SET_COOKIES(response.data.cookies)
          this.getCustomerInfo()
        })
        .catch((error) => {
          this.SET_BUTTON_LOADER(false)
          console.log(error)
          this.error.status = true
          if (error.response.data.data && error.response.data.data.error) {
            let errorMessage = error.response.data.data.error
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)
            this.error.message = errorMessage
          } else {
            this.error.message = 'Something went wrong. Please try again'
          }
        })
    },
    getCustomerInfo() {
      customersApi
        .getCustomerInfo()
        .then((response) => {
          let toasterData = {
            show: true,
            message: 'Welcome back',
          }
          this.SET_TOASTER(toasterData)
          this.SET_CUSTOMER_DATA(response.data.data)
          this.SET_PHONE_VERIFIED(response.data.data.is_phone_verified)
          localStorage.setItem('phoneVerified', response.data.data.is_phone_verified)
          this.SET_LOGGED_IN(true)
          if (this.copperDetails) {
            this.toggleCopperInfo()
          }
          this.closeModal()
          this.SET_BUTTON_LOADER(false)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch(() => {
          this.loading = false
          this.error.status = true
          this.error.message = 'Something went wrong. Please try again'
        })
    },
    closeModal() {
      this.$emit('closeModal')
    },
    signUpClicked() {
      this.$emit('closeModal')
      this.$emit('signUpClicked')
    },
    forgotPasswordClicked() {
      this.$emit('closeModal')
      this.$emit('forgotPasswordClicked')
    },
    togglePassword() {
      if (this.passwordInputType == 'password') {
        this.passwordInputType = 'text'
        this.passwordIconPath = require('@/assets/icons/passwordHide.svg')
      } else {
        this.passwordInputType = 'password'
        this.passwordIconPath = require('@/assets/icons/passwordShow.svg')
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById('email').focus()
    })
  },
}
</script>

<style scoped>
.modalContainer {
  z-index: 9999;
}
.logo {
  width: 80px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signUp {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 15px 0px 20px 0px;
  flex-wrap: wrap;
  justify-content: center;
}
.signUp a,
.forgotPassword {
  font-size: calc(14px * var(--regular-bold-font-size-multiplier));
  font-family: var(--regular-bold-font);
  color: var(--primary-color);
  cursor: pointer;
}
.inputWrapper {
  width: 100%;
  margin-bottom: 10px;
}
button {
  width: 100%;
  margin-top: 20px;
}
.passwordToggle {
  position: absolute;
  right: 10px;
  top: 35px;
}
.feedback {
  margin-top: 15px;
}
</style>
