import { createRouter, createWebHistory } from 'vue-router'

const HomeView = () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
const MenuView = () => import(/* webpackChunkName: "menu" */ '../views/MenuView.vue')
const AccountView = () => import(/* webpackChunkName: "account" */ '../views/AccountView.vue')
const CheckoutView = () => import(/* webpackChunkName: "checkout" */ '../views/CheckoutView.vue')
const ConfirmationView = () => import(/* webpackChunkName: "confirmation" */ '../views/ConfirmationView.vue')
const PasswordResetView = () => import(/* webpackChunkName: "password_reset" */ '../views/PasswordResetView.vue')
const NotFoundView = () => import(/* webpackChunkName: "not_found" */ '../views/Error/NotFoundView.vue')

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/:storeId/:storeName?',
    name: 'MenuView',
    component: MenuView,
  },
  {
    path: '/profile',
    name: 'AccountView',
    component: AccountView,
  },
  {
    path: '/:storeId/:storeName/checkout',
    name: 'CheckoutView',
    component: CheckoutView,
  },
  {
    path: '/confirmation/:orderId',
    name: 'ConfirmationView',
    component: ConfirmationView,
  },
  {
    path: '/password_reset',
    name: 'PasswordResetView',
    component: PasswordResetView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundView',
    component: NotFoundView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
