<template>
  <div @click.self="closeModal" class="modalContainer">
    <div
      class="modalWrapper"
      :style="{
        borderRadius: branding?.display_options?.template == 'bento' ? '0px' : '5px',
        border: branding?.display_options?.template == 'bento' ? '2px solid #000000' : 'none',
      }"
    >
      <div @click="closeModal" class="close">
        <img src="@/assets/icons/close.svg" alt="close" />
      </div>
      <div class="modalHeader" v-if="from == 'orderTimePassed'">
        <h2>Order time passed</h2>
        <p>Please select an order time that is after the current time</p>
      </div>
      <div class="modalHeader" v-if="from == 'needMoreTime'">
        <h2>We need more time</h2>
        <p>{{ message }} Please select a date and time accordingly.</p>
      </div>
      <div v-if="selectedServiceData && !from" class="serviceTypes">
        <label class="serviceTypeLabel">How do you want your order?</label>
        <SegmentedControl
          v-if="selectedStore && visibleServices"
          :elements="visibleServices"
          :elementId="'service_type_id'"
          :selectedId="selectedServiceData.service_type_id"
          :elementName="visibleServices[0].display_name ? 'display_name' : 'name'"
          @change="changeService"
          :loading="buttonLoader"
        />
      </div>
      <div v-if="selectedServiceData && !from" class="line"></div>
      <div v-if="selectedServiceData && !from" class="orderInfo">
        <div>
          <div
            v-if="this.selectedServiceData.requires_address && addressInputIsVisible"
            class="inputWrapper addressInput"
          >
            <div class="addressDetails">
              <div class="addressWrapper">
                <div v-if="selectedAddressLat && selectedAddressLat" class="remove">
                  <a @click="addressValue('')" href="#">
                    <img src="@/assets/icons/close.svg" alt="close" />
                  </a>
                </div>
                <AutoComplete
                  @placeChanged="getLatAndLng"
                  @input="addressValue"
                  :placeholder="'Enter Your Address'"
                  ref="autoComplete"
                />
                <p v-if="v$.address.$error === true" class="validation">Please enter your address</p>
                <p v-if="!addressSelected" class="validation">Please select an address from the suggested list</p>
                <p v-if="validAddressError" class="validation">
                  {{ validAddressError }}
                </p>
              </div>
              <input
                v-if="selectedServiceData.requires_address"
                type="text"
                placeholder="Apt/Suite"
                v-model="aptSuite"
              />
            </div>
          </div>
          <div
            v-if="
              selectedServiceData && selectedServiceData.requires_address && selectedAddress && !addressInputIsVisible
            "
          >
            <p class="name">
              {{ selectedAddress.address1 + ', ' + selectedAddress.state_code + ' ' + selectedAddress.postal_code }}
            </p>
            <p v-if="selectedAddress.address2">Apt/Suite: {{ selectedAddress.address2 }}</p>
          </div>
          <p v-if="selectedStore && selectedServiceData && !selectedServiceData.requires_address" class="name">
            {{ selectedStore.name }}
          </p>
          <p v-if="selectedStore && !selectedServiceData.requires_address">
            {{ selectedStore.address1 }}, {{ selectedStore.city }}, {{ selectedStore.state }}
            {{ selectedStore.postal_code }}
          </p>
        </div>
        <svg
          id="chevron.right"
          xmlns="http://www.w3.org/2000/svg"
          width="9.551"
          height="16.963"
          viewBox="0 0 9.551 16.963"
          @click="changeLocation"
          v-if="selectedServiceData && !selectedServiceData.requires_address"
          style="cursor: pointer"
        >
          <rect id="Rectangle_3463" data-name="Rectangle 3463" width="9.551" height="16.963" opacity="0" />
          <path
            id="Path_19795"
            data-name="Path 19795"
            d="M9.551,8.477a.874.874,0,0,0-.283-.645L1.533.254A.908.908,0,0,0,.889,0,.874.874,0,0,0,0,.889a.927.927,0,0,
                        0,.254.635L7.363,8.477.254,15.43A.909.909,0,0,0,0,16.065a.874.874,0,0,0,.889.889.877.877,0,0,0,.645-.264L9.268,9.121A.89.89,0,0,0,9.551,8.477Z"
            fill="#000000"
          />
        </svg>
        <a
          @click="changeAddressClicked"
          class="change"
          href="javascript:;"
          v-if="selectedServiceData && selectedServiceData.requires_address && !addressInputIsVisible"
        >
          <p>Change</p>
        </a>
      </div>
      <div v-if="selectedStoreDays && day && hour && !from" class="line"></div>
      <div v-if="selectedStoreDays && day && hour" class="daysAndHours">
        <div ref="dayDropdown" v-if="selectedStoreDays" class="inputWrapper">
          <label>Select Day</label>
          <div @click="toggleDayDropdown" class="customSelect">
            <img class="arrow" src="@/assets/icons/downArrow.svg" alt="downArrow" />
            <p>{{ day.formatted }}</p>
          </div>
          <div v-if="dayDropdownIsOpen" class="dropdown">
            <div
              v-for="(day, index) in selectedStoreDays"
              :key="day.date"
              @click="selectDay(index)"
              class="dropdownItem"
              :class="{ selected: index == selectedDayIndex }"
            >
              {{ day.formatted }}
            </div>
          </div>
        </div>
        <div ref="hourDropdown" v-if="selectedStoreDays" class="inputWrapper">
          <label>Select Time</label>
          <div @click="toggleHourDropdown" class="customSelect">
            <img class="arrow" src="@/assets/icons/downArrow.svg" alt="downArrow" />
            <p>{{ hour.formatted }}</p>
          </div>
          <div v-if="hourDropdownIsOpen" class="dropdown">
            <div
              v-for="(hour, index) in selectedStoreDays[selectedDayIndex].hours"
              :key="hour.date"
              @click="selectHour(index)"
              class="dropdownItem"
              :class="{ selected: index == selectedHourIndex }"
            >
              {{ hour.formatted }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="loaderWrapper">
        <div class="loader"></div>
      </div>
      <p class="feedback error" v-if="error.status">
        {{ error.message }}
      </p>
      <button @click="buttonClicked" :disabled="buttonLoader || !selectedStoreDays || !day || !hour" class="startOrder">
        <div v-if="buttonLoader" class="loader buttonLoader"></div>
        <template v-if="!buttonLoader">{{ dayAndTimeSelected ? 'UPDATE' : 'START MY ORDER' }}</template>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { mapState, mapMutations } from 'vuex'

import customersApi from '@/api/customersApi'
import ordersApi from '@/api/ordersApi'
import storesApi from '@/api/storesApi'

import AutoComplete from '@/components/GoogleMaps/AutoComplete.vue'
import SegmentedControl from '@/components/Elements/SegmentedControl.vue'

import addressMixins from '@/mixins/addressMixins'
import modalMixins from '@/mixins/modalMixins.js'

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      day: null,
      hour: null,
      selectedDayIndex: 0,
      selectedHourIndex: 0,
      dayDropdownIsOpen: false,
      hourDropdownIsOpen: false,
      error: {
        status: false,
        message: '',
      },
      serviceTypeListIsVisible: false,
      storeServices: null,
      selectedServiceData: null,
      selectedAddressLat: null,
      selectedAddressLng: null,
      validAddressError: null,
      addressData: null,
      address: '',
      addressInputIsVisible: null,
      addressSelected: true,
      aptSuite: '',
    }
  },
  props: ['from', 'message'],
  components: {
    AutoComplete,
    SegmentedControl,
  },
  validations() {
    return {
      address: { required },
    }
  },
  mixins: [addressMixins, modalMixins],
  watch: {
    selectedAddress() {
      if (this.selectedAddress) {
        this.addressInputIsVisible = false
      }
    },
  },
  computed: {
    ...mapState([
      'selectedStoreDays',
      'selectedDay',
      'selectedHour',
      'selectedService',
      'selectedStore',
      'dayAndTimeSelected',
      'buttonLoader',
      'orderData',
      'loggedIn',
      'branding',
      'cookies',
      'selectedAddress',
      'storeConfig',
      'customerData',
    ]),
    visibleServices() {
      return this.selectedStore.services.filter((service) => service.is_visible)
    },
  },
  methods: {
    ...mapMutations([
      'SET_SELECTED_STORE_DAYS',
      'SET_SELECTED_DAY',
      'SET_SELECTED_HOUR',
      'SET_DAY_AND_TIME_SELECTED',
      'SET_ORDER_DATA',
      'SET_BUTTON_LOADER',
      'SET_TOASTER',
      'SET_LOGGED_IN',
      'SET_SELECTED_COMPONENT',
      'SET_CUSTOMER_DATA',
      'SET_COOKIES',
      'SET_SELECTED_SERVICE',
      'SET_SELECTED_ADDRESS',
      'RESET_COOKIES',
    ]),
    closeModal() {
      this.$emit('closeModal')
    },
    toggleServiceTypeList() {
      this.serviceTypeListIsVisible = !this.serviceTypeListIsVisible
    },
    changeService(service) {
      this.SET_SELECTED_ADDRESS(null)
      this.addressInputIsVisible = true
      this.error = {
        status: false,
        message: '',
      }
      this.serviceTypeListIsVisible = false
      this.selectedServiceData = service
      this.validAddressError = null
      this.getStoreDays()
    },
    addressValue(value) {
      if (value === '') {
        this.$refs.autoComplete.resetInput()
        this.selectedAddressLat = null
        this.selectedAddressLng = null
        this.validAddressError = null
        this.SET_SELECTED_ADDRESS(null)
      }
      this.address = value
    },
    changeAddressClicked() {
      this.addressInputIsVisible = true
      this.error = {
        status: false,
        message: '',
      }
      this.aptSuite = ''
      this.selectedAddressLat = null
      this.selectedAddressLng = null
      this.address = ''
      this.addressData = null
    },
    getLatAndLng(addressData) {
      this.getAddressComponents(addressData, false, true)
      if (this.validAddressError) {
        return
      }
      this.selectedAddressLat = addressData.geometry.location.lat()
      this.selectedAddressLng = addressData.geometry.location.lng()
    },
    async saveAddress() {
      this.SET_BUTTON_LOADER(true)
      let customerID = this.loggedIn ? localStorage.getItem('customerId') : localStorage.getItem('guestCustomerId')
      this.addressData.phone = this.customerData ? this.customerData.phone : null
      this.addressData.contact_phone = this.customerData ? this.customerData.phone : null
      this.addressData.address2 = this.aptSuite ? this.aptSuite : ''
      await customersApi
        .saveAddress(this.addressData, customerID)
        .then((response) => {
          localStorage.setItem('selectedAddress', JSON.stringify(response.data.data))
          this.SET_SELECTED_ADDRESS(response.data.data)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch(() => {
          this.SET_BUTTON_LOADER(false)
          let toasterData = {
            show: true,
            message: 'Something went wrong. Please try again later',
          }
          this.SET_TOASTER(toasterData)
        })
    },
    changeLocation() {
      this.$emit('closeModal')
      this.$router.push({ name: 'HomeView' })
    },
    selectDay(index) {
      this.day = this.selectedStoreDays[index]
      this.selectedDayIndex = index
      this.selectedHourIndex = 0
      this.hour = this.selectedStoreDays[index].hours[0]
      this.toggleDayDropdown()
    },
    selectHour(index) {
      this.selectedHourIndex = index
      this.hour = this.day.hours[index]
      this.toggleHourDropdown()
    },
    toggleDayDropdown() {
      this.dayDropdownIsOpen = !this.dayDropdownIsOpen
    },
    toggleHourDropdown() {
      this.hourDropdownIsOpen = !this.hourDropdownIsOpen
    },
    logOut() {
      customersApi
        .logout()
        .then((response) => {
          if (response.data.data.ok == 'Logged out') {
            this.SET_LOGGED_IN(false)
            this.SET_ORDER_DATA(null)
            localStorage.removeItem('customerId')
            localStorage.removeItem('orderId')
            localStorage.removeItem('selectedComponent')
            localStorage.removeItem('selectedTip')
            localStorage.removeItem('dayAndTimeSelected')
            localStorage.removeItem('auth')
            localStorage.removeItem('phoneVerified')
            localStorage.removeItem('newCardData')
            localStorage.removeItem('otherTipAmount')
            this.RESET_COOKIES()
            this.SET_SELECTED_COMPONENT('ProfileView')
            this.SET_DAY_AND_TIME_SELECTED(false)
            this.SET_CUSTOMER_DATA(null)
            this.$router.push('/')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    createOrder() {
      this.SET_BUTTON_LOADER(true)
      let serviceTypeId = this.selectedServiceData
        ? this.selectedServiceData.service_type_id
        : localStorage.getItem('selectedServiceId')
      let payload = {
        customer_id: this.loggedIn ? localStorage.getItem('customerId') : localStorage.getItem('guestCustomerId'),
        is_asap: this.hour.hour == 'ASAP' ? true : false,
        store_id: this.selectedStore.store_id,
        service_type_id: parseInt(serviceTypeId),
      }
      let discountCode = localStorage.getItem('discountCode')
      if (discountCode) {
        payload.discount_code = discountCode
      }
      if (this.hour.hour != 'ASAP') {
        payload.ready_ts = this.day.date + ' ' + this.hour.hour
      }
      if (
        this.selectedServiceData ? this.selectedServiceData.requires_address : this.selectedService.requires_address
      ) {
        let address = JSON.parse(localStorage.getItem('selectedAddress'))
        payload.address_id = address.address_id
      }
      ordersApi
        .createOrder(payload)
        .then((response) => {
          this.SET_BUTTON_LOADER(false)
          localStorage.setItem('orderId', response.data.data.order.order_id)
          this.SET_ORDER_DATA(response.data.data)
          localStorage.setItem('dayAndTimeSelected', true)
          this.SET_DAY_AND_TIME_SELECTED(true)
          this.SET_SELECTED_DAY(this.day)
          localStorage.setItem('selectedDay', JSON.stringify(this.day))
          this.SET_SELECTED_HOUR(this.hour)
          localStorage.setItem('selectedHour', JSON.stringify(this.hour))
          this.SET_COOKIES(response.data.cookies)
          this.SET_SELECTED_SERVICE(this.selectedServiceData)
          localStorage.setItem('selectedServiceId', this.selectedServiceData.service_type_id)
          localStorage.removeItem('discountCode')
          if (response.data.data.pending_discounts) {
            this.$emit('openDiscountModal', response.data.data.pending_discounts)
          }
          this.closeModal()
        })
        .catch((error) => {
          console.log(error)
          this.SET_BUTTON_LOADER(false)
          if (error.response.data.status == 403) {
            let toasterData = {
              show: true,
              message: 'Your session is expired. Please login again.',
            }
            this.SET_TOASTER(toasterData)
            this.logOut()
            return
          }
          this.error.status = true
          this.error.message = error.response.data.data.error
            ? error.response.data.data.error
            : 'Something went wrong. Please try again later'
        })
    },
    updateOrder() {
      this.SET_BUTTON_LOADER(true)
      let serviceTypeId = this.selectedServiceData
        ? this.selectedServiceData.service_type_id
        : localStorage.getItem('selectedServiceId')
      let payload = {
        customer_id: this.loggedIn ? localStorage.getItem('customerId') : localStorage.getItem('guestCustomerId'),
        is_asap: this.hour.hour == 'ASAP' ? true : false,
        store_id: this.selectedStore.store_id,
        service_type_id: parseInt(serviceTypeId),
        order_id: this.orderData.order.order_id,
      }
      if (this.hour.hour != 'ASAP') {
        payload.ready_ts = this.day.date + ' ' + this.hour.hour
      }
      if (this.selectedServiceData.requires_address) {
        let address = JSON.parse(localStorage.getItem('selectedAddress'))
        payload.address_id = address ? address.address_id : this.selectedAddress.address_id
      }
      ordersApi
        .updateOrder(payload)
        .then((response) => {
          this.SET_BUTTON_LOADER(false)
          this.SET_ORDER_DATA(response.data.data)
          this.SET_SELECTED_DAY(this.day)
          localStorage.setItem('selectedDay', JSON.stringify(this.day))
          this.SET_SELECTED_HOUR(this.hour)
          localStorage.setItem('selectedHour', JSON.stringify(this.hour))
          this.SET_COOKIES(response.data.cookies)
          this.SET_SELECTED_SERVICE(this.selectedServiceData)
          localStorage.setItem('selectedServiceId', this.selectedServiceData.service_type_id)
          if (this.from == 'orderTimePassed' || this.from == 'needMoreTime') {
            this.$emit('placeOrder', 'dateTimeModal')
          }
          this.closeModal()
        })
        .catch((error) => {
          console.log(error)
          this.SET_BUTTON_LOADER(false)
          if (error.response.data.status == 403) {
            let toasterData = {
              show: true,
              message: 'Your session is expired. Please login again.',
            }
            this.SET_TOASTER(toasterData)
            this.logOut()
            return
          }
          this.error.status = true
          this.error.message = error.response.data.data.error
            ? error.response.data.data.error
            : 'Something went wrong. Please try again later'
        })
    },
    async buttonClicked() {
      if (
        (this.selectedServiceData.requires_address && !this.selectedAddress) ||
        (this.selectedServiceData.requires_address && this.addressInputIsVisible)
      ) {
        // validate the input fields
        this.v$.$touch()
        // check if the address is selected from the suggested list
        if (!this.addressData) {
          this.addressSelected = false
        }
        if (this.v$.$error || !this.addressSelected) {
          if (!this.addressSelected) {
            this.addressSelected = false
          }
          return
        }
      }
      if (!this.loggedIn) {
        // guest login
        await this.guestCustomerLogin()
      }
      if (this.selectedServiceData.requires_address) {
        if (!this.selectedAddress || this.addressInputIsVisible) {
          await this.saveAddress()
        }
        let response = await this.getDeliveryInfo()
        if (response.error) {
          this.error.status = true
          this.error.message = response.error
          this.SET_BUTTON_LOADER(false)
          return
        }
      }
      if (this.dayAndTimeSelected) {
        this.updateOrder()
      } else {
        this.createOrder()
      }
    },
    async guestCustomerLogin() {
      this.SET_BUTTON_LOADER(true)
      await customersApi
        .guestLogin()
        .then((response) => {
          if (response.data.data.ok == true) {
            localStorage.setItem('guestCustomerId', response.data.data.customer_id)
            this.SET_COOKIES(response.data.cookies)
          }
        })
        .catch((error) => {
          this.SET_BUTTON_LOADER(false)
          console.log(error)
        })
    },
    getStoreServices() {
      let storeId = this.$route.params.storeId
      storesApi
        .getStore(storeId)
        .then((response) => {
          // get selected service type id from local storage
          let selectedServiceTypeId = localStorage.getItem('selectedServiceId')
          if (selectedServiceTypeId) {
            // if selected service type id is present in local storage first check if it is present in the response
            let selectedService = response.data.data.services.find(
              (service) => service.service_type_id == selectedServiceTypeId
            )
            if (selectedService) {
              // if selected service type id is present in the response then set it as selected service
              this.selectedServiceData = selectedService
            } else {
              // if selected service type id is not present in the response then set the first service as selected service
              this.selectedServiceData = response.data.data.services[0]
              this.SET_SELECTED_SERVICE(response.data.data.services[0])
              localStorage.setItem('selectedServiceId', response.data.data.services[0].service_type_id)
              localStorage.setItem('selectedServiceData', JSON.stringify(response.data.data.services[0]))
              localStorage.setItem('timeZoneOffset', response.data.data.timezone_offset_mins)
            }
          } else {
            // if selected service type id is not present in local storage then set the first service as selected service
            this.selectedServiceData = response.data.data.services[0]
            this.SET_SELECTED_SERVICE(response.data.data.services[0])
            localStorage.setItem('selectedServiceId', response.data.data.services[0].service_type_id)
            localStorage.setItem('selectedServiceData', JSON.stringify(response.data.data.services[0]))
            localStorage.setItem('timeZoneOffset', response.data.data.timezone_offset_mins)
          }
          this.getStoreDays()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async getDeliveryInfo() {
      this.SET_BUTTON_LOADER(true)
      let responseData
      let serviceTypeId = this.selectedServiceData
        ? this.selectedServiceData.service_type_id
        : localStorage.getItem('selectedServiceId')
      let payload = {
        service_schedule: true,
        service_type_id: serviceTypeId,
        latitude: this.selectedAddress.latitude,
        longitude: this.selectedAddress.longitude,
        store_id: this.$route.params.storeId,
      }
      await storesApi
        .getStore(null, payload)
        .then((response) => {
          responseData = response.data.data[0]
          if (response.data.data[0].delivery_info) {
            localStorage.setItem('deliveryInfo', JSON.stringify(response.data.data[0].delivery_info))
          }
        })
        .catch((error) => {
          if (error.response.data.data.error == 'no stores found') {
            responseData = {
              error: 'Your address is not in the delivery area of this store. Please enter another address.',
            }
          } else {
            responseData = error.response.data.data
          }
        })
      return responseData
    },
    getStoreDays() {
      let serviceTypeId = this.selectedServiceData
        ? this.selectedServiceData.service_type_id
        : localStorage.getItem('selectedServiceId')
      let payload = {
        service_schedule: true,
        service_type_id: serviceTypeId,
      }
      storesApi
        .getStore(this.$route.params.storeId, payload)
        .then((response) => {
          let serviceData = response.data.data
          let today = response.data.data.today
          let timeZoneOffset = response.data.data.timezone_offset_mins
          this.getDaysAndHours(serviceData, serviceTypeId, today, timeZoneOffset)
          if (response.data.data.delivery_info) {
            localStorage.setItem('deliveryInfo', JSON.stringify(response.data.data.delivery_info))
          }
          localStorage.setItem('timeZoneOffset', response.data.data.timezone_offset_mins)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
          let toasterData = {
            show: true,
            message: 'Something went wrong. Please try again later',
          }
          this.SET_TOASTER(toasterData)
        })
    },
    getDaysAndHours(serviceData, serviceTypeId, today, timeZoneOffset) {
      let availableHoursList = []
      // find the restaurants current time in military time
      let restaurantCurrentTimeWithTimezone = moment().utcOffset(timeZoneOffset).format('HH:mm')
      if (typeof serviceData.service_schedule[serviceTypeId] === 'undefined') {
        return null
      }
      Object.entries(serviceData.service_schedule[serviceTypeId]).forEach(([day, v_list]) => {
        if (v_list.length == 0) {
          delete serviceData.service_schedule[serviceTypeId][day]
        }
        // if any of the v_list is 1440 or more, add it to the next day.
        v_list.forEach((hourRange) => {
          if (hourRange[1] > 1440) {
            let nextDay = moment(day, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
            if (typeof serviceData.service_schedule[serviceTypeId][nextDay] === 'undefined') {
              serviceData.service_schedule[serviceTypeId][nextDay] = []
            }
            serviceData.service_schedule[serviceTypeId][nextDay].push([0, hourRange[1] - 24 * 60])
          }
        })
      })
      Object.entries(serviceData.service_schedule[serviceTypeId]).forEach(([day, v_list]) => {
        // if day is before today then skip
        if (moment(day, 'YYYY-MM-DD').isBefore(moment(today, 'MM/DD/YYYY'), 'day')) {
          return
        }
        // get the hours for the day
        var hours = []
        v_list.forEach((hourRange) => {
          for (var hr = hourRange[0]; hr <= hourRange[1]; hr += 15) {
            let militaryTime = this.calculateTimeToHour(hr)
            // if hr is 1440 or return
            if (hr >= 1440) {
              return
            }
            hours.push({
              hour: militaryTime,
              formatted: moment(militaryTime, 'HH:mm').format('h:mm A'),
            })
          }
          if (hourRange[1] % 15 !== 0) {
            let militaryTime = this.calculateTimeToHour(hourRange[1])
            // if hr is 1440 return
            if (hr >= 1440) {
              return
            }
            hours.push({
              hour: militaryTime,
              formatted: moment(militaryTime, 'HH:mm').format('h:mm A'),
            })
          }
        })

        // sort the hours
        hours.sort((a, b) => {
          return moment(a.hour, 'HH:mm').diff(moment(b.hour, 'HH:mm'))
        })
        availableHoursList.push({
          date: day,
          formatted:
            day == moment(today, 'MM/DD/YYYY').format('YYYY-MM-DD')
              ? 'TODAY'
              : day == moment(today, 'MM/DD/YYYY').add(1, 'days').format('YYYY-MM-DD')
              ? 'TOMORROW'
              : moment(day, 'YYYY-MM-DD').format('MMM D, YYYY'),
          hours: hours,
        })
      })
      availableHoursList.forEach((day) => {
        // if the day is today then remove hours that have already passed
        if (day.date == moment().utcOffset(timeZoneOffset).format('YYYY-MM-DD')) {
          day.hours = day.hours.filter((hour) => {
            return moment(hour.hour, 'HH:mm').isAfter(moment(restaurantCurrentTimeWithTimezone, 'HH:mm'))
          })
        }
        // if the day is today and there are no hours after the current time then remove the day
        if (day.date == moment().utcOffset(timeZoneOffset).format('YYYY-MM-DD') && day.hours.length == 0) {
          availableHoursList = availableHoursList.filter((d) => d.date != day.date)
        }
        // if the day is today and the store is open then add asap
        if (
          day.date == moment().utcOffset(timeZoneOffset).format('YYYY-MM-DD') &&
          day.hours.length > 0 &&
          this.selectedStore.is_open
        ) {
          day.hours.unshift({
            hour: 'ASAP',
            asapTime: restaurantCurrentTimeWithTimezone,
            formatted: 'ASAP',
          })
        }
      })
      // sort by date
      availableHoursList.sort((a, b) => {
        return moment(a.date).diff(moment(b.date))
      })
      this.SET_SELECTED_STORE_DAYS(availableHoursList)
      localStorage.setItem('selectedStoreDays', JSON.stringify(availableHoursList))
      if (this.selectedStoreDays && !this.dayAndTimeSelected) {
        this.day = this.selectedStoreDays[0]
        this.hour = this.selectedStoreDays[0].hours[0]
      } else {
        // find the index of the selected day
        this.selectedDayIndex = this.selectedStoreDays.findIndex((day) => day.date === this.selectedDay.date)
        if (this.selectedDayIndex == -1) {
          this.selectedDayIndex = 0
        }
        // find the index of the selected hour
        try {
          this.selectedHourIndex = this.selectedStoreDays[this.selectedDayIndex].hours.findIndex(
            (hour) => hour.hour === this.selectedHour.hour
          )
          this.day = this.selectedStoreDays[this.selectedDayIndex]
        } catch (error) {
          this.selectedHourIndex = -1
        }
        if (this.selectedHourIndex == -1) {
          this.hour = this.selectedStoreDays[this.selectedDayIndex].hours[0]
          this.selectedHourIndex = 0
        } else {
          this.hour = this.selectedStoreDays[this.selectedDayIndex].hours[this.selectedHourIndex]
        }
      }
    },
    calculateTimeToHour(h) {
      return ('0' + Math.floor(parseInt(h) / 60)).slice(-2) + ':' + ('0' + (h % 60)).slice(-2)
    },
  },
  created() {
    this.getStoreServices()
    localStorage.setItem('selectedStoreId', this.$route.params.storeId)
    this.addressInputIsVisible = this.selectedAddress ? false : true
  },
  mounted() {
    if (this.orderData) {
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          this.closeModal()
        }
      })
    }
    // if dayDropdownIsOpen is open, close it when user clicks outside of it
    document.addEventListener('click', (e) => {
      if (this.dayDropdownIsOpen) {
        if (!this.$refs.dayDropdown.contains(e.target)) {
          this.dayDropdownIsOpen = false
        }
      }
    })

    // if hourDropdownIsOpen is open, close it when user clicks outside of it
    document.addEventListener('click', (e) => {
      if (this.hourDropdownIsOpen) {
        if (!this.$refs.hourDropdown.contains(e.target)) {
          this.hourDropdownIsOpen = false
        }
      }
    })
  },
  beforeUnmount() {
    if (this.orderData) {
      document.removeEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          this.closeModal()
        }
      })
    }
    // remove event listeners
    document.removeEventListener('click', (e) => {
      if (this.dayDropdownIsOpen) {
        if (!this.$refs.dayDropdown.contains(e.target)) {
          this.dayDropdownIsOpen = false
        }
      }
    })
    document.removeEventListener('click', (e) => {
      if (this.hourDropdownIsOpen) {
        if (!this.$refs.hourDropdown.contains(e.target)) {
          this.hourDropdownIsOpen = false
        }
      }
    })
  },
}
</script>

<style scoped>
.daysAndHours {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.customSelect {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  height: 44px;
  font-size: calc(16px * var(--regular-font-size-multiplier));
  padding: 0px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.customSelect p {
  font-size: calc(16px * var(--regular-font-size-multiplier));
}
.arrow {
  position: absolute;
  bottom: 18px;
  right: 10px;
}
.type {
  font-family: var(--regular-bold-font);
}
.name {
  font-size: 20px;
  font-family: var(--regular-bold-font);
}
.startOrder {
  margin-top: 20px;
}
.dropdown {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: 7px;
  height: 200px;
  overflow: auto;
}
.dropdownItem {
  padding: 10px 15px;
  cursor: pointer;
}
.dropdownItem:hover {
  background-color: var(--background-color);
}
.dropdownItem:first-child {
  margin-top: 10px;
}
.dropdownItem:last-child {
  margin-bottom: 10px;
}
.selected {
  font-family: var(--regular-bold-font);
  color: var(--primary-color);
  background-color: var(--background-color);
}
.modalWrapper {
  overflow: unset;
}
.change {
  cursor: pointer;
  width: max-content;
}
.change p {
  color: var(--primary-color);
  width: max-content;
}
.feedback {
  margin-top: 15px;
}
.loaderWrapper {
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listWrapper {
  position: relative;
  margin-bottom: 15px;
}
.selectedListItem {
  height: 44px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  cursor: pointer;
  margin-top: 10px;
}
.selectedListItem p {
  font-size: calc(18px * var(--regular-font-size-multiplier));
}
.orderTypesList {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  z-index: 2;
}
.listItem {
  padding: 5px 15px;
  cursor: pointer;
}
.listItem p {
  font-size: calc(16px * var(--regular-font-size-multiplier));
}
.listItem:hover {
  background-color: var(--background-color);
}
.listItem:first-child {
  margin-top: 10px;
}
.listItem:last-child {
  margin-bottom: 10px;
}
.orderInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.remove {
  position: absolute;
  top: 3px;
  right: 0px;
  padding: 10px;
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 5px;
}
.remove a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove a img {
  height: 14px;
  width: 14px;
}
.addressInput {
  margin-bottom: 20px;
}
.inputWrapper input {
  border: none;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0;
  padding-left: 0;
  font-size: calc(16px * var(--regular-font-size-multiplier));
}
.inputWrapper {
  margin-bottom: 0px;
}
.close {
  background-color: transparent;
}
.addressDetails {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 15px;
}
.addressDetails input {
  border: none;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0;
  padding-left: 0;
  font-size: calc(16px * var(--regular-font-size-multiplier));
}
.addressWrapper {
  position: relative;
}
.modalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.modalHeader p {
  text-align: center;
}
.serviceTypeLabel {
  font-size: 18px;
  font-family: var(--regular-font);
  margin-bottom: 15px;
}
.line {
  height: 1px;
  background-color: var(--border-color);
  width: 100%;
  margin: 20px 0px;
  flex-shrink: 0;
}
.orderTypesSelector {
  background-color: var(--gray-color);
  border-radius: 5px;
  padding: 5px;
  margin-top: 15px;
}
.serviceTypes {
  display: flex;
  flex-direction: column;
}
</style>
