<template>
  <nav v-if="$route.name != 'ConfirmationView'">
    <transition name="fade">
      <LoginModal
        v-if="loginModalIsOpen"
        @closeModal="toggleLoginModal"
        @signUpClicked="toggleSignUpModal"
        @forgotPasswordClicked="toggleForgotPasswordModal"
        @toggleCopperInfo="toggleCopperLoyaltyModal"
      />
    </transition>
    <transition name="fade">
      <SignUpModal
        v-if="signUpModalIsOpen"
        @closeModal="toggleSignUpModal"
        @loginClicked="toggleLoginModal"
        @accountCreated="togglePhoneVerificationModal"
        @toggleCopperInfo="toggleCopperLoyaltyModal"
      />
    </transition>
    <transition name="fade">
      <ForgotPasswordModal
        v-if="forgotPasswordModalIsOpen"
        @closeModal="toggleForgotPasswordModal"
        @signUpClicked="toggleSignUpModal"
        @backToLogin="toggleLoginModal"
      />
    </transition>
    <transition name="fade">
      <DateAndTimeModal v-if="dateAndTimeModalIsOpen" @closeModal="toggleDateAndTimeModal" />
    </transition>
    <transition name="fade">
      <PhoneVerificationModal v-if="phoneVerificationModalIsOpen" @closeModal="togglePhoneVerificationModal" />
    </transition>
    <transition name="fade">
      <CopperLoyaltyModal v-if="copperLoyaltyModalIsOpen" @closeModal="toggleCopperLoyaltyModal" />
    </transition>
    <div
      :class="{
        navOnMenu: $route.name == 'MenuView' || $route.name == 'CheckoutView' || $route.name == 'ConfirmationView',
      }"
      class="navWrapper"
    >
      <div class="left">
        <img class="logo" @click="goToLocationsPage" :src="getLogo" alt="companyLogo" />
      </div>
      <div class="right">
        <a v-if="loggedIn && customerData?.is_mgmt && checkIfInternalUser" @click="jumpToAdmin" class="navLink" href="javascript:;">
          <p>Jump To Admin</p>
        </a>
        <a v-if="!loggedIn" @click="toggleLoginModal" class="navLink" href="javascript:;" data-cy="login-modal-button">
          <!-- <img style="max-height: 24px" :src="branding.display_options.icons.profile" alt="profile" /> -->
          <p>Login</p>
        </a>
        <a
          v-if="loggedIn && customerData"
          @click="goToProfilePage"
          class="navLink"
          href="javascript:;"
          ref="profileDropdown"
          data-cy="profile-button"
        >
          <!-- <img style="max-height: 24px" :src="branding.display_options.icons.profile" alt="profile" /> -->
          <p>Profile</p>
          <div v-if="profileDropdownIsVisible" class="profileDropdown">
            <div @click="openProfile('profile')" class="dropdownLink">
              <a>Profile</a>
            </div>
            <div
              v-if="
                (storeConfig.require_phone_verification && customerData.is_phone_verified) ||
                !storeConfig.require_phone_verification
              "
              @click="openProfile('addresses')"
              class="dropdownLink"
            >
              <a>Addresses</a>
            </div>
            <div
              v-if="
                ((customerData.is_phone_verified && storeConfig.require_phone_verification) ||
                  !storeConfig.require_phone_verification) &&
                branding &&
                branding.display_options.display_rewards_page
              "
              @click="openProfile('rewards')"
              class="dropdownLink"
            >
              <a>Rewards</a>
            </div>
            <div
              v-if="
                (storeConfig.require_phone_verification && customerData.is_phone_verified) ||
                !storeConfig.require_phone_verification
              "
              @click="openProfile('favoriteItems')"
              class="dropdownLink"
            >
              <a>Favorite Items</a>
            </div>
            <div
              v-if="!customerData.is_phone_verified && storeConfig.require_phone_verification"
              @click="openProfile('verifyPhone')"
              class="dropdownLink"
            >
              <a>Verify Phone</a>
            </div>
            <div
              v-if="
                (customerData.is_phone_verified && storeConfig.require_phone_verification) ||
                !storeConfig.require_phone_verification
              "
              @click="openProfile('manageCards')"
              class="dropdownLink"
            >
              <a>Manage Cards</a>
            </div>
            <div
              v-if="
                (customerData.is_phone_verified && storeConfig.require_phone_verification) ||
                !storeConfig.require_phone_verification
              "
              @click="openProfile('orderHistory')"
              class="dropdownLink"
            >
              <a>Order History</a>
            </div>
            <a @click="logOut" class="logOut" href="javascript:;">
              <p>LOG OUT</p>
            </a>
          </div>
        </a>
        <a
          v-if="$route.name == 'MenuView' || ($route.name == 'CheckoutView' && windowWidth <= 1000)"
          @click="toggleCartViewModal"
          class="navLink cartLink"
          href="javascript:;"
        >
          <!-- <img class="cartImage" :src="branding.display_options.icons.cart" alt="cart" /> -->
          <p
            v-if="orderData"
            :style="{
              color: branding.display_options.icons.color_on_cart_icon
                ? branding.display_options.icons.color_on_cart_icon
                : '#FFFFFF',
            }"
            class="cartQty"
          >
            Cart ({{ orderData ? getItemTotal : 0 }})
          </p>
          <div v-if="itemToaster.show" class="itemToaster">
            <p>{{ itemToaster.message }}</p>
          </div>
        </a>
      </div>
    </div>
  </nav>
  <div v-if="$route.name == 'ConfirmationView'" class="confirmationHeader"></div>
  <div
    v-if="
      !(
        $route.name == 'HomeView' ||
        $route.name == 'CheckoutView' ||
        $route.name == 'AccountView' ||
        $route.name == 'NotFoundView' ||
        $route.name == 'ConfirmationView'
      ) &&
      dayAndTimeSelected &&
      selectedService &&
      selectedStore &&
      orderData
    "
    class="middle"
  >
    <div class="line">
      <svg width="15" height="16" viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.33496 15.0947C11.2168 15.0947 14.4321 11.8794 14.4321 7.99756C14.4321 4.11572 
                11.2095 0.900391 7.32764 0.900391C3.45312 0.900391 0.237793 4.11572 0.237793 7.99756C0.237793 
                11.8794 3.45312 15.0947 7.33496 15.0947ZM7.33496 13.8423C4.09766 13.8423 1.50488 11.2422 
                1.50488 7.99756C1.50488 4.75293 4.09033 2.15283 7.32764 2.15283C10.5723 2.15283 13.1797 4.75293 
                13.1797 7.99756C13.1797 11.2422 10.5796 13.8423 7.33496 13.8423ZM3.75342 8.78125H7.32764C7.61328 
                8.78125 7.84033 8.56152 7.84033 8.27588V3.64697C7.84033 3.36865 7.61328 3.14893 7.32764 3.14893C7.04932 
                3.14893 6.82959 3.36865 6.82959 3.64697V7.77783H3.75342C3.46777 7.77783 3.24805 7.99756 3.24805 
                8.27588C3.24805 8.56152 3.46777 8.78125 3.75342 8.78125Z"
          fill="#1400FF"
        />
      </svg>
      <p class="date">
        {{ selectedDay.formatted }} @ {{ selectedHour.formatted }}
        <span v-if="selectedHour.hour == 'ASAP'">({{ formatTime(this.orderData.order.dp_stamp) }})</span>
      </p>
    </div>
    <div class="line">
      <svg width="13" height="15" viewBox="0 0 13 15" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.265137 12.231C0.265137 13.6812 1.01221 14.4136 2.47705 14.4136H10.6289C11.896 14.4136 
                12.6357 13.6812 12.6357 12.231V5.06055C12.6357 3.61768 11.8887 2.87793 10.4312 2.87793H9.36182C9.3252 
                1.39844 8.04346 0.138672 6.4541 0.138672C4.85742 0.138672 3.57568 1.39844 3.53906 2.87793H2.47705C1.01221 
                2.87793 0.265137 3.61768 0.265137 5.06055V12.231ZM4.71826 2.87793C4.74756 1.98438 5.47998 1.24463 
                6.4541 1.24463C7.4209 1.24463 8.15332 1.98438 8.18262 2.87793H4.71826ZM1.46631 12.1577V5.14111C1.46631 
                4.43799 1.83984 4.0791 2.51367 4.0791H10.3872C11.061 4.0791 11.4419 4.43799 11.4419 
                5.14111V12.1577C11.4419 12.8608 11.061 13.2197 10.585 13.2197H2.51367C1.83984 13.2197 1.46631 
                12.8608 1.46631 12.1577Z"
          fill="#1400FF"
        />
      </svg>
      <p class="type">
        {{
          selectedService.display_name
            ? selectedService.display_name.toUpperCase()
            : selectedService.name.toUpperCase()
        }}
        <span class="typeIndicator">{{ selectedService.requires_address ? 'TO' : 'FROM' }}:</span>
      </p>
      <p class="addressInfo">
        {{
          selectedService.requires_address && selectedAddress
            ? selectedAddress.address1 + ', ' + selectedAddress.state_code + ' ' + selectedAddress.postal_code
            : selectedStore.address1 + ', ' + selectedStore.state + ' ' + selectedStore.postal_code
        }}
      </p>
    </div>
    <a @click="toggleDateAndTimeModal" class="edit" href="javascript:;">
      <p>CHANGE</p>
    </a>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import moment from 'moment'

import LoginModal from '@/components/Modals/AuthenticationModals/LoginModal.vue'
import SignUpModal from '@/components/Modals/AuthenticationModals/SignUpModal.vue'
import ForgotPasswordModal from '@/components/Modals/AuthenticationModals/ForgotPasswordModal.vue'
import DateAndTimeModal from '@/components/Modals/StoreModals/DateAndTimeModal.vue'
import PhoneVerificationModal from '@/components/Modals/AuthenticationModals/PhoneVerificationModal.vue'
import CopperLoyaltyModal from '@/components/Modals/StoreModals/CopperLoyaltyModal.vue'

import customersApi from '@/api/customersApi'

export default {
  data() {
    return {
      loginModalIsOpen: false,
      signUpModalIsOpen: false,
      forgotPasswordModalIsOpen: false,
      dateAndTimeModalIsOpen: false,
      phoneVerificationModalIsOpen: false,
      profileDropdownIsVisible: false,
      cartViewIsOpen: false,
      windowWidth: window.innerWidth,
      copperLoyaltyModalIsOpen: false,
    }
  },
  components: {
    LoginModal,
    SignUpModal,
    ForgotPasswordModal,
    DateAndTimeModal,
    PhoneVerificationModal,
    CopperLoyaltyModal,
  },
  computed: {
    ...mapState([
      'loggedIn',
      'selectedDay',
      'selectedHour',
      'selectedService',
      'selectedStore',
      'dayAndTimeSelected',
      'customerData',
      'orderData',
      'phoneVerified',
      'branding',
      'cartIsOpen',
      'cookies',
      'storeConfig',
      'selectedAddress',
      'itemToaster',
    ]),
    getLogo() {
      return this.branding.logo_url
    },
    getItemTotal() {
      let itemQty = 0
      this.orderData.order.items.forEach((item) => {
        itemQty += item.quantity
      })
      return itemQty
    },
    checkIfInternalUser() {
      // check if customerData.email includes @lunchbox.io
      if (this.customerData.email.includes('@lunchbox.io')) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_LOGGED_IN',
      'SET_ORDER_DATA',
      'SET_SELECTED_COMPONENT',
      'SET_DAY_AND_TIME_SELECTED',
      'SET_CUSTOMER_DATA',
      'SET_CART_IS_OPEN',
      'SET_COOKIES',
      'RESET_COOKIES',
      'SET_COPPER_DETAILS',
    ]),
    formatTime(time) {
      let asapTime = time.split(' ')[1]
      return moment(asapTime, 'HH:mm').format('h:mm A')
    },
    goToLocationsPage() {
      this.$router.push({ name: 'HomeView' })
    },
    toggleLoginModal() {
      this.loginModalIsOpen = !this.loginModalIsOpen
    },
    toggleSignUpModal() {
      this.signUpModalIsOpen = !this.signUpModalIsOpen
    },
    toggleForgotPasswordModal() {
      this.forgotPasswordModalIsOpen = !this.forgotPasswordModalIsOpen
    },
    toggleDateAndTimeModal() {
      this.dateAndTimeModalIsOpen = !this.dateAndTimeModalIsOpen
    },
    togglePhoneVerificationModal() {
      this.phoneVerificationModalIsOpen = !this.phoneVerificationModalIsOpen
    },
    toggleCartViewModal() {
      this.SET_CART_IS_OPEN(!this.cartIsOpen)
    },
    toggleCopperLoyaltyModal() {
      this.copperLoyaltyModalIsOpen = !this.copperLoyaltyModalIsOpen
    },
    goToProfilePage() {
      if (window.innerWidth <= 900) {
        this.profileDropdownIsVisible = !this.profileDropdownIsVisible
      } else {
        this.$router.push({ name: 'AccountView' })
      }
    },
    jumpToAdmin() {
      let cookies = JSON.stringify(this.cookies)
      let payload = {
        cookies: cookies,
        tenant: process.env.VUE_APP_TENANT,
      }
      // base64 encode the string
      let encodedData = btoa(JSON.stringify(payload))
      let adminUrl = 'https://dashboard.lunchbox.io/autologin'
      // add the encoded string to the url and open the admin in a new tab
      let url = adminUrl + '?params=' + encodedData
      window.open(url, '_blank')
    },
    openProfile(page) {
      switch (page) {
        case 'profile':
          this.$router.push({ name: 'AccountView' })
          localStorage.setItem('selectedComponent', 'ProfileView')
          this.SET_SELECTED_COMPONENT('ProfileView')
          break
        case 'addresses':
          this.$router.push({ name: 'AccountView' })
          localStorage.setItem('selectedComponent', 'AddressesView')
          this.SET_SELECTED_COMPONENT('AddressesView')
          break
        case 'rewards':
          this.$router.push({ name: 'AccountView' })
          localStorage.setItem('selectedComponent', 'RewardsView')
          this.SET_SELECTED_COMPONENT('RewardsView')
          break
        case 'favoriteItems':
          this.$router.push({ name: 'AccountView' })
          localStorage.setItem('selectedComponent', 'FavoriteItemsView')
          this.SET_SELECTED_COMPONENT('FavoriteItemsView')
          break
        case 'verifyPhone':
          this.togglePhoneVerificationModal()
          break
        case 'manageCards':
          this.$router.push({ name: 'AccountView' })
          localStorage.setItem('selectedComponent', 'ManageCardsView')
          this.SET_SELECTED_COMPONENT('ManageCardsView')
          break
        case 'orderHistory':
          this.$router.push({ name: 'AccountView' })
          localStorage.setItem('selectedComponent', 'OrderHistoryView')
          this.SET_SELECTED_COMPONENT('OrderHistoryView')
          break
        default:
          break
      }
    },
    logOut() {
      customersApi
        .logout()
        .then((response) => {
          if (response.data.data.ok == 'Logged out') {
            this.SET_LOGGED_IN(false)
            this.SET_ORDER_DATA(null)
            localStorage.removeItem('customerId')
            localStorage.removeItem('orderId')
            localStorage.removeItem('selectedComponent')
            localStorage.removeItem('selectedTip')
            localStorage.removeItem('dayAndTimeSelected')
            localStorage.removeItem('auth')
            localStorage.removeItem('phoneVerified')
            localStorage.removeItem('newCardData')
            localStorage.removeItem('otherTipAmount')
            this.RESET_COOKIES()
            this.SET_DAY_AND_TIME_SELECTED(false)
            this.SET_CUSTOMER_DATA(null)
            this.$router.push('/')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  created() {
    if (localStorage.getItem('customerId')) {
      this.SET_LOGGED_IN(true)
    }

    // copper
    if (this.$route.query.merchant && this.$route.query.location && this.$route.query.ticket) {
      let copperDetails = {
        merchant: this.$route.query.merchant,
        location: this.$route.query.location,
        ticket: this.$route.query.ticket,
      }
      /* localStorage.setItem('copperDetails', JSON.stringify(copperDetails)) */
      this.SET_COPPER_DETAILS(copperDetails)
      if (!this.loggedIn) {
        this.toggleLoginModal()
      } else {
        this.toggleCopperLoyaltyModal()
      }
    }

    window.addEventListener('resize', () => {
      const innerWidth = window.innerWidth
      this.windowWidth = innerWidth
    })
  },
  mounted() {
    // if profileDropdown is open, close it when user clicks outside of it
    document.addEventListener('click', (e) => {
      if (this.profileDropdownIsVisible) {
        if (!this.$refs.profileDropdown.contains(e.target)) {
          this.profileDropdownIsVisible = false
        }
      }
    })
  },
  beforeUnmount() {
    // remove event listener when component is destroyed
    document.removeEventListener('click', (e) => {
      if (this.profileDropdownIsVisible) {
        if (!this.$refs.profileDropdown.contains(e.target)) {
          this.profileDropdownIsVisible = false
        }
      }
    })
  },
}
</script>

<style scoped>
nav {
  background-color: var(--background-color);
  border-bottom: 2px solid #000000;
}
.navWrapper {
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 0px 0px 0px 20px;
}
.navOnMenu {
  width: 100%;
  margin: auto;
}
.navLink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
  padding: 0px 25px;
  border-left: 2px solid #000000;
}
.navLink p {
  cursor: pointer;
}
.logo {
  height: 50px;
  cursor: pointer;
}
.orderInfo {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.orderInfo p {
  font-size: 13px;
}
.left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.edit {
  margin-left: 10px;
  border: 1px solid var(--primary-text-color);
  border-radius: 20px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}
.cartQty {
  color: #ffffff;
  font-size: calc(13px * var(--regular-font-size-multiplier));
  text-align: center;
  margin-top: 3px;
}
.profileDropdown {
  position: absolute;
  top: 55px;
  right: 0px;
  background-color: #ffffff;
  border: 2px solid #000000;
  display: flex;
  flex-direction: column;
  z-index: 12;
  width: 160px;
  overflow: hidden;
}
.dropdownLink {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-size: calc(15px * var(--regular-font-size-multiplier));
  cursor: pointer;
}
.dropdownLink:hover {
  background-color: var(--background-color);
  font-family: var(--regular-bold-font);
}
.dropdownLink:first-child {
  margin-top: 5px;
}
.dropdownLink:last-child {
  margin-bottom: 5px;
}
.dropdownLink a {
  color: var(--text-color);
}
.logOut {
  font-family: var(--regular-bold-font);
  cursor: pointer;
  margin: auto;
  padding: 15px 0px;
}
.logOut p {
  color: var(--primary-color);
}
.cart {
  display: none;
}
.navLinks {
  display: flex;
  gap: 20px;
  align-items: center;
}
.cartIcon {
  background-color: #ffffff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.cartIcon img {
  width: 24px;
}
.cartIcon p {
  margin-left: 5px;
  color: #292828;
}
.cartView {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 5;
  max-width: 650px;
}
.cartWrapper {
  height: 100%;
  border-radius: 0;
}
.mobile {
  display: none;
}
.middle {
  display: flex;
  column-gap: 20px;
  row-gap: 5px;
  align-items: center;
  margin: auto;
  background-color: var(--primary-color);
  width: 100%;
  padding: 10px 5px;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 2px solid #000000;
}
.middle svg path {
  fill: #ffffff;
}
.middle p,
.middle span {
  color: var(--primary-text-color);
  font-family: var(--regular-bold-font);
}
.middle a p {
  color: var(--primary-text-color);
  font-size: calc(13px * var(--regular-bold-font-size-multiplier));
}
.middle svg path {
  fill: var(--primary-text-color);
}
.right {
  display: flex;
  justify-content: flex-end;
}
.line {
  display: flex;
  gap: 5px;
  align-items: center;
}
.navLink svg path {
  fill: var(--text-color);
}
.cartImage {
  width: 33px;
  height: 33px;
  margin-bottom: 5px;
  object-fit: contain;
}
.cartImage path {
  fill: var(--primary-color) !important;
}
.typeIndicator {
  font-family: var(--regular-bold-font);
}
.itemToaster {
  position: absolute;
  top: 60px;
  right: 0px;
  width: max-content;
  background-color: var(--text-color);
  padding: 15px;
  border-radius: 5px;
}
.itemToaster p {
  color: #ffffff;
  font-size: calc(16px * var(--regular-font-size-multiplier));
  font-family: var(--regular-font) !important;
}
.confirmationHeader {
  height: 15px;
  background-color: var(--primary-color);
  width: 100%;
  flex-shrink: 0;
}
.cartLink {
  background-color: var(--primary-color);
  border-left: none;
}

@media screen and (max-width: 500px) {
  .middle {
    column-gap: 10px;
  }
  .addressInfo {
    display: none;
  }
  .line p {
    font-size: calc(13px * var(--regular-bold-font-size-multiplier));
  }
  .typeIndicator {
    display: none;
  }
  .logo {
    height: 35px;
    max-width: 160px;
    object-fit: contain;
  }
  .edit {
    margin-left: 0;
  }
  .navLink {
    padding: 0px 15px;
  }
}
</style>
