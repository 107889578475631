import { createStore } from 'vuex'

import router from '../router'

import storesApi from '../api/storesApi'
import ordersApi from '@/api/ordersApi'
import customersApi from '@/api/customersApi'

export default createStore({
  state: {
    storeConfig: null,
    selectedService:
      localStorage.getItem('selectedServiceData') && localStorage.getItem('selectedServiceData') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedServiceData'))
        : null,
    loggedIn: false,
    selectedStore: null,
    orderData: null,
    phoneVerified: localStorage.getItem('phoneVerified') == 'true' ? true : false,
    selectedStoreDays: JSON.parse(localStorage.getItem('selectedStoreDays')) || null,
    selectedDay: JSON.parse(localStorage.getItem('selectedDay')) || null,
    selectedHour: JSON.parse(localStorage.getItem('selectedHour')) || null,
    selectedPaymentMethod: JSON.parse(localStorage.getItem('selectedPaymentMethod')) || null,
    buttonLoader: false,
    userEmail: localStorage.getItem('userEmail') || null,
    dayAndTimeSelected: localStorage.getItem('dayAndTimeSelected') == 'true' ? true : false,
    customerData: null,
    selectedComponent: localStorage.getItem('selectedComponent') || 'ProfileView',
    toaster: [],
    itemToaster: {
      message: '',
      show: false,
    },
    selectedAddress: JSON.parse(localStorage.getItem('selectedAddress')) || null,
    branding: null,
    cartIsOpen: false,
    cookies: JSON.parse(localStorage.getItem('cookies')) || {
      __ac: '',
      'NovaDine.session': '',
      ROUTE_ID: '',
    },
    copperDetails: null,
  },
  mutations: {
    SET_STORE_CONFIG(state, storeConfig) {
      if (storeConfig.loyalty) {
        let tiers = storeConfig.loyalty.programs_information
        // sort the tiers by threshold from lowest to highest
        tiers.sort((a, b) => a.threshold - b.threshold)
        storeConfig.loyalty.programs_information = tiers
      }
      localStorage.setItem('chainName', storeConfig.chain_name)
      state.storeConfig = storeConfig
    },
    SET_SELECTED_SERVICE(state, selectedService) {
      state.selectedService = selectedService
      // set the selected service in local storage
      localStorage.setItem('selectedServiceData', JSON.stringify(selectedService))
    },
    SET_LOGGED_IN(state, loggedIn) {
      state.loggedIn = loggedIn
    },
    SET_SELECTED_STORE(state, selectedStore) {
      state.selectedStore = selectedStore
    },
    SET_ORDER_DATA(state, orderData) {
      state.orderData = orderData
    },
    SET_PHONE_VERIFIED(state, phoneVerified) {
      state.phoneVerified = phoneVerified
    },
    SET_SELECTED_STORE_DAYS(state, selectedStoreDays) {
      state.selectedStoreDays = selectedStoreDays
    },
    SET_SELECTED_DAY(state, selectedDay) {
      state.selectedDay = selectedDay
    },
    SET_SELECTED_HOUR(state, selectedHour) {
      state.selectedHour = selectedHour
    },
    SET_SELECTED_PAYMENT_METHOD(state, selectedPaymentMethod) {
      state.selectedPaymentMethod = selectedPaymentMethod
    },
    SET_BUTTON_LOADER(state, buttonLoader) {
      state.buttonLoader = buttonLoader
    },
    SET_USER_EMAIL(state, userEmail) {
      state.userEmail = userEmail
    },
    SET_DAY_AND_TIME_SELECTED(state, dayAndTimeSelected) {
      state.dayAndTimeSelected = dayAndTimeSelected
    },
    SET_CUSTOMER_DATA(state, customerData) {
      state.customerData = customerData
    },
    SET_SELECTED_COMPONENT(state, selectedComponent) {
      state.selectedComponent = selectedComponent
    },
    SET_TOASTER(state, payload) {
      payload.time = Date().now
      state.toaster.push(payload)
      // after 3 seconds remove the toaster
      setTimeout(() => {
        state.toaster.shift()
      }, payload.time || 3000)
    },
    SET_ITEM_TOASTER(state, payload) {
      state.itemToaster = payload
      setTimeout(() => {
        state.itemToaster.show = false
      }, payload.time || 3000)
    },
    SET_SELECTED_ADDRESS(state, selectedAddressId) {
      state.selectedAddress = selectedAddressId
    },
    SET_BRANDING(state, payload) {
      if (!payload.display_options.template) {
        payload.display_options.template = 'default'
      }
      state.branding = payload
    },
    SET_CART_IS_OPEN(state, payload) {
      state.cartIsOpen = payload
    },
    SET_COOKIES(state, payload) {
      if (payload) {
        if (payload.__ac) {
          state.cookies.__ac = payload.__ac
        }
        if (payload['NovaDine.session']) {
          state.cookies['NovaDine.session'] = payload['NovaDine.session']
        }
        if (payload.ROUTE_ID) {
          state.cookies.ROUTE_ID = payload.ROUTE_ID
        }
        localStorage.setItem('cookies', JSON.stringify(state.cookies))
      }
    },
    RESET_COOKIES(state) {
      state.cookies = { __ac: '', 'NovaDine.session': '', ROUTE_ID: '' }
      localStorage.setItem('cookies', JSON.stringify(state.cookies))
    },
    SET_COPPER_DETAILS(state, payload) {
      state.copperDetails = payload
    },
  },
  actions: {
    getStoreConfig({ commit, dispatch }) {
      if (this.state.storeConfig) return
      storesApi
        .getConfiguration()
        .then((response) => {
          commit('SET_STORE_CONFIG', response.data.data)
          commit('SET_COOKIES', response.data.cookies)
          if (localStorage.getItem('selectedServiceId') === null) {
            // go through the services and find the first one that is not service_type_id 1
            let service = response.data.data.services.find((service) => !service.requires_address && service.is_visible)
            let routeName = router.currentRoute.value.name
            if (
              (service && (routeName == 'HomeView' || routeName == 'PasswordResetView')) ||
              routeName == 'ConfirmationView'
            ) {
              localStorage.setItem('selectedServiceId', service.service_type_id)
              commit('SET_SELECTED_SERVICE', service)
            }
            // if there is no service that does not require address, set the first service as the selected service
            if (!service) {
              localStorage.setItem('selectedServiceId', response.data.data.services[0].service_type_id)
              commit('SET_SELECTED_SERVICE', response.data.data.services[0])
            }
          }
          dispatch('getSelectedService')
          if (!response.data.data.services[0].requires_address) {
            dispatch('getSelectedStore')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getSelectedStore({ commit }) {
      if (this.state.selectedStore) return
      let storeId = localStorage.getItem('selectedStoreId')
      if (!storeId) return
      storesApi
        .getStore(storeId)
        .then((response) => {
          commit('SET_SELECTED_STORE', response.data.data)
          commit('SET_COOKIES', response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getSelectedService({ commit }) {
      if (this.state.selectedService) return
      let selectedServiceId = localStorage.getItem('selectedServiceId')
      let selectedService = this.state.storeConfig.services.find(
        (service) => service.service_type_id == selectedServiceId
      )
      commit('SET_SELECTED_SERVICE', selectedService)
    },
    getOrderData({ commit }) {
      if (this.state.orderData || localStorage.getItem('orderId') === null) return
      let orderId = localStorage.getItem('orderId')
      ordersApi
        .getOrder(orderId)
        .then((response) => {
          commit('SET_ORDER_DATA', response.data.data)
          commit('SET_COOKIES', response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCustomerData({ commit }) {
      if (this.state.customerData && this.state.loggedIn) return
      customersApi
        .getCustomerInfo()
        .then((response) => {
          commit('SET_CUSTOMER_DATA', response.data.data)
          commit('SET_COOKIES', response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async getBranding({ commit }) {
      if (this.state.branding) return
      await storesApi
        .getBranding()
        .then((response) => {
          commit('SET_BRANDING', response.data.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  modules: {},
})
