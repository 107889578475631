import axios from 'axios'
import endpoints from './endpoints.js'
import { createRequestData, url } from './constants.js'

// login
const login = async (payload) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.LOGIN, 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// get customer info
const getCustomerInfo = async () => {
  let requestData = createRequestData(endpoints.CUSTOMERS.INFO, 'GET')
  return await axios.post(url, requestData)
}

// create account
const createAccount = async (payload) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.REGISTER, 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// reset password
const resetPassword = async (payload) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.RESET_PASSWORD, 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// send verification code
const sendVerificationCode = async (payload) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.SEND_VERIFICATION_CODE, 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// verify phone
const verifyPhone = async (payload) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.VERIFY_PHONE, 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// update customer
const updateCustomer = async (payload, customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.UPDATE_CUSTOMER.replace(':customerId', customerId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// get addresses
const getAddresses = async (customerId, payload) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.ADDRESSES.replace(':customerId', customerId), 'GET')
  if (payload) {
    requestData = { ...requestData, ...payload }
  }
  return await axios.post(url, requestData)
}

// get favorite items
const getFavoriteItems = async (customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.FAVORITE_ITEMS.replace(':customerId', customerId), 'GET')
  return await axios.post(url, requestData)
}

// add favorite item
const addFavoriteItem = async (payload, customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.ADD_FAVORITE_ITEM.replace(':customerId', customerId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// remove favorite item
const removeFavoriteItem = async (payload, customerId) => {
  let requestData = createRequestData(
    endpoints.CUSTOMERS.REMOVE_FAVORITE_ITEM.replace(':customerId', customerId),
    'DELETE'
  )
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// get saved cards
const getSavedCards = async (customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.SAVED_CARDS.replace(':customerId', customerId), 'GET')
  return await axios.post(url, requestData)
}

// get loyalty info
const getLoyaltyInfo = async (customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.LOYALTY_INFO.replace(':customerId', customerId), 'GET')
  return await axios.post(url, requestData)
}

// get orders
const getOrders = async (customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.ORDERS.replace(':customerId', customerId), 'GET')
  return await axios.post(url, requestData)
}

// save address
const saveAddress = async (payload, customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.SAVE_ADDRESS.replace(':customerId', customerId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// update address
const updateAddress = async (payload, customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.UPDATE_ADDRESS.replace(':customerId', customerId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// delete address
const deleteAddress = async (payload, customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.DELETE_ADDRESS.replace(':customerId', customerId), 'DELETE')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// save card
const saveCard = async (payload, customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.SAVE_CARD.replace(':customerId', customerId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// delete card
const deleteCard = async (payload, customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.DELETE_CARD.replace(':customerId', customerId), 'DELETE')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// change password
const changePassword = async (payload) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.CHANGE_PASSWORD, 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// delete customer
const deleteCustomer = async (customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.DELETE_CUSTOMER.replace(':customerId', customerId), 'DELETE')
  return await axios.post(url, requestData)
}

// add favorite store
const addFavoriteStore = async (payload, customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.ADD_FAVORITE_STORE.replace(':customerId', customerId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// remove favorite store
const removeFavoriteStore = async (payload, customerId) => {
  let requestData = createRequestData(
    endpoints.CUSTOMERS.REMOVE_FAVORITE_STORE.replace(':customerId', customerId),
    'DELETE'
  )
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// get favorite stores
const getFavoriteStores = async (customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.FAVORITE_STORES.replace(':customerId', customerId), 'GET')
  return await axios.post(url, requestData)
}

// guest login
const guestLogin = async () => {
  let requestData = createRequestData(endpoints.CUSTOMERS.GUEST_LOGIN, 'POST')
  return await axios.post(url, requestData)
}

// update guest customer info
const updateGuestCustomerInfo = async (payload, customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.UPDATE_GUEST_INFO.replace(':customerId', customerId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// logout
const logout = async () => {
  let requestData = createRequestData(endpoints.CUSTOMERS.LOGOUT, 'POST')
  return await axios.post(url, requestData)
}

// redeem loyalty in store
const redeemLoyaltyInStore = async (payload, customerId) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.REDEEM_IN_STORE.replace(':customerId', customerId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// collect loyalty in store
const collectLoyaltyInStore = async (payload) => {
  let requestData = createRequestData(endpoints.CUSTOMERS.COLLECT_IN_STORE, 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

export default {
  login,
  getCustomerInfo,
  createAccount,
  resetPassword,
  sendVerificationCode,
  verifyPhone,
  updateCustomer,
  getAddresses,
  getFavoriteItems,
  addFavoriteItem,
  removeFavoriteItem,
  getSavedCards,
  getLoyaltyInfo,
  getOrders,
  saveAddress,
  updateAddress,
  deleteAddress,
  saveCard,
  deleteCard,
  changePassword,
  deleteCustomer,
  addFavoriteStore,
  removeFavoriteStore,
  getFavoriteStores,
  guestLogin,
  updateGuestCustomerInfo,
  logout,
  redeemLoyaltyInStore,
  collectLoyaltyInStore
}
