<template>
  <div class="toasterWrapper">
    <TransitionGroup name="toaster">
      <div v-for="toaster in toaster" :key="toaster" class="toaster">
        <p>{{ toaster.message }}</p>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ToasterView',
  computed: {
    ...mapState(['toaster']),
  },
}
</script>

<style scoped>
.toasterWrapper {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999999999;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
}
.toaster {
  display: flex;
  background-color: var(--text-color);
  padding: 15px;
  border-radius: 5px;
}
.toaster p {
  color: #fff;
  text-align: left;
  font-size: calc(16px * var(--regular-font-size-multiplier));
}
.toaster-enter-from,
.toaster-leave-to {
  transform: translateX(-120%);
}
.toaster-move,
.toaster-enter-active,
.toaster-leave-active {
  transition: all 0.3s ease-in-out;
}
.toaster-enter-to,
.toaster-leave-from {
  transform: translateX(0%);
}

@media screen and (max-width: 600px) {
  .toasterWrapper {
    bottom: unset;
    top: 10px;
    left: 10px;
    margin-right: 10px;
  }
  .toaster p {
    font-size: calc(14px * var(--regular-font-size-multiplier));
  }
}
</style>
