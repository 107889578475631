<template>
  <div class="validations">
    <div class="validation">
      <div v-if="!validation.atLeastEightCharacters" class="notValid"></div>
      <svg v-if="validation.atLeastEightCharacters" xmlns="http://www.w3.org/2000/svg" width="18.605" height="14.979" viewBox="0 0 18.605 14.979">
        <path
          id="Path_19212"
          data-name="Path 19212"
          d="M-11890.857,4515.093l6.958,4.871,8.949-11.012"
          transform="translate(11892.004 -4507.69)"
          fill="none"
          stroke="#fff"
          stroke-width="4"
        />
      </svg>
      <p>At least 8 characters</p>
    </div>
    <div class="validation">
      <div v-if="!validation.atLeastOneNumber" class="notValid"></div>
      <svg v-if="validation.atLeastOneNumber" xmlns="http://www.w3.org/2000/svg" width="18.605" height="14.979" viewBox="0 0 18.605 14.979">
        <path
          id="Path_19212"
          data-name="Path 19212"
          d="M-11890.857,4515.093l6.958,4.871,8.949-11.012"
          transform="translate(11892.004 -4507.69)"
          fill="none"
          stroke="#fff"
          stroke-width="4"
        />
      </svg>
      <p>At least one number</p>
    </div>
    <div class="validation">
      <div v-if="!validation.atLeastOneUppercaseLetter" class="notValid"></div>
      <svg v-if="validation.atLeastOneUppercaseLetter" xmlns="http://www.w3.org/2000/svg" width="18.605" height="14.979" viewBox="0 0 18.605 14.979">
        <path
          id="Path_19212"
          data-name="Path 19212"
          d="M-11890.857,4515.093l6.958,4.871,8.949-11.012"
          transform="translate(11892.004 -4507.69)"
          fill="none"
          stroke="#fff"
          stroke-width="4"
        />
      </svg>
      <p>Uppercase letters</p>
    </div>
    <div class="validation">
      <div v-if="!validation.atLeastOneSpecialCharacter" class="notValid"></div>
      <svg v-if="validation.atLeastOneSpecialCharacter" xmlns="http://www.w3.org/2000/svg" width="18.605" height="14.979" viewBox="0 0 18.605 14.979">
        <path
          id="Path_19212"
          data-name="Path 19212"
          d="M-11890.857,4515.093l6.958,4.871,8.949-11.012"
          transform="translate(11892.004 -4507.69)"
          fill="none"
          stroke="#fff"
          stroke-width="4"
        />
      </svg>
      <p>One special character</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      validation: {
        atLeastEightCharacters: false,
        atLeastOneNumber: false,
        atLeastOneUppercaseLetter: false,
        atLeastOneSpecialCharacter: false,
      },
    }
  },
  props: ['password'],
  watch: {
    password() {
      this.validatePassword()
      this.checkIfValid()
    },
  },
  methods: {
    validatePassword() {
      this.validation.atLeastEightCharacters = this.password.length >= 8
      this.validation.atLeastOneNumber = /\d/.test(this.password)
      this.validation.atLeastOneUppercaseLetter = /[A-Z]/.test(this.password)
      this.validation.atLeastOneSpecialCharacter = /[^A-Za-z0-9]/.test(this.password)
    },
    checkIfValid() {
      const valid = Object.values(this.validation).every((value) => value === true)
      this.$emit('valid', valid)
    },
  },
}
</script>

<style scoped>
.validations {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  column-gap: 20px;
  row-gap: 5px;
  margin: 15px 0px;
}
.validation {
  display: flex;
  align-items: center;
  gap: 5px;
}
.validation svg path {
  stroke: var(--custom-green-color);
}
.notValid {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #000000;
}
</style>
